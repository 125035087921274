import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEdit,
  faTrash,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "./DropdownMenu.css";
const DropdownMenu = ({ onClose, id }) => {
  return (
    <div className="faEllipsis-drop">
      <ul>
        <li className="dropdown-item">
          <Link to={`${id}`} onClick={onClose}>
            <FontAwesomeIcon icon={faEye} />
            <span>مشاهدة التفاصيل</span>
          </Link>
        </li>
        <li className="dropdown-item">
          <Link to={`edit/${id}`} onClick={onClose}>
            <FontAwesomeIcon icon={faEdit} />
            <span>تعديل</span>
          </Link>
        </li>
        <li className="dropdown-item">
          <Link to="/trainee/delete" className="delete-link" onClick={onClose}>
            <FontAwesomeIcon icon={faTrashAlt} />
            <span>حذف المستخدم</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default DropdownMenu;
