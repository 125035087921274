import React, { useState } from "react";

const GoalsSection = ({ goals, onGoalsChange }) => {
  const [inputValueGoal, setInputValueGoal] = useState("");

  const handleKeyDownGoals = (e) => {
    if (e.key === "Enter" && inputValueGoal.trim()) {
      e.preventDefault();
      const newGoals = [...goals, inputValueGoal.trim()];
      onGoalsChange(newGoals);
      setInputValueGoal("");
    }
  };

  const handleAddGoal = (e) => {
    e.preventDefault();
    if (inputValueGoal.trim()) {
      const newGoals = [...goals, inputValueGoal.trim()];
      onGoalsChange(newGoals);
      setInputValueGoal("");
    }
  };

  const handleDeleteGoal = (indexToDelete) => {
    const newGoals = goals.filter((_, index) => index !== indexToDelete);
    onGoalsChange(newGoals);
  };

  return (
    <div className="personal-info">
      <h5
        className="personal-info-h5"
        style={{
          color: "black",
          fontWeight: "500",
          marginBottom: "20px",
        }}
      >
        الاهداف
      </h5>
      <div className="">
        <div className="p-1 info-1 d-flex align-items-center gap-4 flex-wrap col-lg-12 col-md-4 col-2">
          <div
            className="w-full bg-white rounded-2xl shadow-[0_2px_8px_rgba(0,0,0,0.08)] p-1"
            dir="rtl"
          >
            {/* Header */}
            <div className="flex justify-between items-center mb-4 flex-wrap">
              <div className="relative-input mb-1 ">
                <input
                  type="text"
                  value={inputValueGoal}
                  onChange={(e) => setInputValueGoal(e.target.value)}
                  onKeyDown={handleKeyDownGoals}
                  placeholder="أدخل الهدف..."
                  
                  className="w-full px-4 py-3 text-[14px] bg-gray-50 border border-gray-100 rounded-xl focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-gray-200 transition-all duration-200 placeholder:text-gray-400"
                />
              </div>
              <button
                onClick={handleAddGoal}
                className="text-[#ff6b00] hover:text-[#ff8533] text-sm flex items-center gap-1 transition-colors duration-200 font-medium skills-btn"
              >
                <span className="text-lg" style={{ fontSize: "25px" }}>
                  +
                </span>
                <span>إضافة</span>
              </button>
            </div>

            {/* Goals List */}
            <div className="info-parent d-flex flex-wrap col-lg-12 col-md-2 col-2">
              <div
                className="maker d-flex flex-column gap-2"
                style={{ width: "50%" }}
              >
                {goals
                  .slice(0, Math.ceil(goals.length / 2))
                  .map((item, index) => (
                    <div
                      className="d-flex align-items-center p-2 gap-2"
                      key={index}
                    >
                      <img
                        src={require("./../../../../Pages/Dashboard/images/close-circle.png")}
                        alt="tick-circle"
                        onClick={() => handleDeleteGoal(index)}
                        style={{ cursor: "pointer" }}
                      />
                      <p
                        style={{
                          margin: "0",
                          fontWeight: "500",
                          color: "black",
                        }}
                      >
                        {item}
                      </p>
                    </div>
                  ))}
              </div>
              <div
                className="maker d-flex flex-column gap-2"
                style={{ width: "50%" }}
              >
                {goals.slice(Math.ceil(goals.length / 2)).map((item, index) => (
                  <div
                    className="d-flex align-items-center p-2 gap-2"
                    key={index + Math.ceil(goals.length / 2)}
                  >
                    <img
                      src={require("./../../../../Pages/Dashboard/images/close-circle.png")}
                      alt="tick-circle"
                      onClick={() =>
                        handleDeleteGoal(index + Math.ceil(goals.length / 2))
                      }
                      style={{ cursor: "pointer" }}
                    />
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        color: "black",
                      }}
                    >
                      {item}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoalsSection;
