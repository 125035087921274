import React from "react";
import { Trash2 } from "lucide-react";

const SkillsSection = ({ skills, onSkillsChange }) => {
  const [inputValue, setInputValue] = React.useState("");

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim()) {
      e.preventDefault();
      const newSkills = [...skills, inputValue.trim()];
      onSkillsChange(newSkills);
      setInputValue("");
    }
  };

  const handleAddSkill = (e) => {
    e.preventDefault();
    if (inputValue.trim()) {
      const newSkills = [...skills, inputValue.trim()];
      onSkillsChange(newSkills);
      setInputValue("");
    }
  };

  const handleDeleteSkill = (indexToDelete) => {
    const newSkills = skills.filter((_, index) => index !== indexToDelete);
    onSkillsChange(newSkills);
  };

  return (
    <div className="personal-info">
      <h5
        className="personal-info-h5"
        style={{
          color: "black",
          fontWeight: "500",
          marginBottom: "20px",
        }}
      >
        المهارات
      </h5>
      <div className="">
        <div className="p-1 info-1 d-flex align-items-center gap-4 flex-wrap col-lg-12 col-md-4 col-2">
          <div
            className="w-full bg-white rounded-2xl shadow-[0_2px_8px_rgba(0,0,0,0.08)] p-1"
            dir="rtl"
          >
            {/* Header */}
            <div className="flex justify-between items-center mb-4 flex-wrap">
              <div className="relative-input mb-1 ">
                <input
                  type="text"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={handleKeyDown}
                  
                  placeholder="اضف مهارة اخرى... "
                  className="w-full px-4 py-3 text-[14px] bg-gray-50 border border-gray-100 rounded-xl focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-gray-200 transition-all duration-200 placeholder:text-gray-400"
                />
              </div>
              <button
                onClick={handleAddSkill}
                className="text-[#ff6b00] hover:text-[#ff8533] text-sm flex items-center gap-1 transition-colors duration-200 font-medium skills-btn"
              >
                <span className="text-lg" style={{ fontSize: "25px" }}>
                  +
                </span>
                <span>إضافة</span>
              </button>
            </div>

            {/* Skills List */}
            <div className="flex flex-wrap gap-2">
              {skills.map((skill, index) => (
                <div
                  key={index}
                  className="group flex items-center gap-2 bg-[#EEF2FF] hover:bg-[#E5EDFF] text-[#4F46E5] rounded-xl text-sm transition-colors duration-200 skills-delete"
                >
                  <span className="font-medium">{skill}</span>
                  <button
                    onClick={() => handleDeleteSkill(index)}
                    className="text-[#4F46E5] hover:text-red-500 transition-colors duration-200 skills-delete-btn"
                    aria-label="حذف المهارة"
                  >
                    <Trash2 size={15} />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillsSection;
