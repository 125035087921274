import { Form, FormSelect, Table } from "react-bootstrap";
import "./Trainees.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import PaginatedItems from "../../../../Components/Dashboard/Pagination/Pagination";
import DropdownMenu from "../../../../Components/Dashboard/Helpers/DropdownMenu ";
import Loading from "../../../../Components/Dashboard/Loading/Loading";
import DetailsTopBarUsers from "../../../../Components/Dashboard/DetailsTop/DetailsTopBarUsers";

export default function Trainees() {
  const [trainees, setTraineess] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const dataPaginated = trainees.slice((page - 1) * limit, page * limit);
  function isNumber(value) {
    return /^\d+$/.test(value); // للتحقق إذا كانت القيمة رقمًا فقط
  }

  function handleSearch(e) {
    const value = e.target.value;
    setSearch(value);
  }
  console.log(trainees);
  // داخل const filterdData
  const filterdData = trainees.filter((item) => {
    const searchField = isNumber(search) ? "phone_number" : "name";
    return item[searchField].toLowerCase().includes(search.toLowerCase());
  });

  const showWhichData = search.length > 0 ? filterdData : dataPaginated;
  const header = [
    {
      key: "id",
      name: "كود الموظف",
    },
    {
      key: "name",
      name: "الاسم",
    },
    {
      key: "job",
      name: "الوظيفة",
    },
    {
      key: "country",
      name: "الدولة",
    },
    {
      key: "city",
      name: "المدينة",
    },
    {
      key: "status",
      name: "الحالة",
    },
    {
      key: "select",
      name: "",
    },
  ];
  //Get  User
  useEffect(() => {
    try {
      setLoading(true);
      axios.get("/JSON/Trainees.json").then((res) => {
        setTraineess(res.data);
        console.log(res.data);
        setTotal(res.data.length);
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const headersShow = header.map((item, index) => (
    <th
      className={`table-header ${
        item.key === "name"
          ? "name-column"
          : item.key === "id"
          ? "small-column"
          : ""
      }`}
    >
      {item.name}
    </th>
  ));
  const [openedDropdown, setOpenedDropdown] = useState(null);

  const traineesShow = showWhichData.map((item, index) => (
    <tr className="table-row">
      {header.map((item2, key2) => (
        <td
          className={
            item2.key === "name"
              ? "name-column"
              : item2.key === "id"
              ? "small-column"
              : ""
          }
          key={key2}
        >
          {item2.key === "status" ? (
            <div
              className="show-status"
              style={{
                backgroundColor:
                  item[item2.key] === "مفعل" ? "#B0EED4" : "#F4BCBC",
                color: item[item2.key] === "مفعل" ? "#00C973" : "#DC2626",
              }}
            >
              {item[item2.key]}
            </div>
          ) : item2.key === "select" ? (
            <div className="dropdown-container">
              <FontAwesomeIcon
                onClick={() =>
                  setOpenedDropdown(openedDropdown === index ? null : index)
                }
                cursor="pointer"
                icon={faEllipsis}
              />
              {openedDropdown === index && (
                <DropdownMenu
                  className="faEllipsis-drop"
                  id={item["id"]}
                  onClose={() => setOpenedDropdown(null)}
                />
              )}
            </div>
          ) : (
            item[item2.key]
          )}
        </td>
      ))}
    </tr>
  ));
  return (
    <>
      {loading && <Loading />}
      <div className="trainee">
        <DetailsTopBarUsers
          title="بيانات المتدربين"
          button="اضف متدرب جديد"
          icon="plus"
          link="/dashboard/usersMangement/trainees/add"
          linkParent="ادارة المستخدمين"
          linkChild="المتدربين"
          sendSuccess={true}
        />
        <div className="trainee-table">
          <div className="search-parent">
            <Form.Control
              className="search-trainee my-2"
              type="search"
              aria-label="input example"
              placeholder="ابحث بالاسم او رقم الهاتف"
              onChange={(e) => {
                handleSearch(e);
                // setSearchLoading(true);
              }}
            />
            <i className="search-icon-trainee"></i>
          </div>
          <div className="formtable">
            <Table striped bordered hover className="custom-table">
              <thead>
                <tr>{headersShow}</tr>
              </thead>
              <tbody>{traineesShow}</tbody>
            </Table>
          </div>
          <div className="pagination-parent" style={{ direction: "rtl" }}>
            <div className="d-flex align-items-center justify-content-between">
              <p style={{ margin: "10px" }}>يوجد</p>
              <FormSelect
                class="form-select"
                id="floatingSelect"
                aria-label="Floating label select example"
                style={{
                  width: "110px",
                  color: "#f89523",
                  textAlign: "center",
                }}
                onChange={(e) => setLimit(e.target.value)}
              >
                <option
                  style={{ textAlign: "center", marginRight: "10px" }}
                  value="5"
                >
                  5
                </option>
                <option value="8">8</option>

                <option value="10">10</option>
                <option value="15">15</option>
              </FormSelect>
              <p style={{ margin: "10px" }}>مستخدمين فالصفحة الواحدة</p>
            </div>
            <PaginatedItems
              setPage={setPage}
              itemsPerPage={limit}
              data={trainees}
              total={total}
            />
          </div>
        </div>
      </div>
    </>
  );
}
