import React, { useState } from "react";

const InstructorsSection = ({
  workshops: instructors, // renamed for clarity
  onWorkshopsChange: onInstructorsChange, // renamed for clarity
  head_title,
  instructorsAll,
}) => {
  const [selectedInstructor, setSelectedInstructor] = useState("");

  const handleKeyDownInstructor = (e) => {
    if (e.key === "Enter" && selectedInstructor.trim()) {
      e.preventDefault();
      addInstructor();
    }
  };

  const addInstructor = (e) => {
    e.preventDefault();
    if (selectedInstructor.trim()) {
      // Split the selected value into name and avatar
      const [name, avatar] = selectedInstructor.split("|");
      const newInstructor = {
        name: name.trim(),
        avatar: avatar.trim(),
      };

      // Check if instructor already exists
      const instructorExists = instructors.some(
        (instructor) => instructor.name === newInstructor.name
      );

      if (!instructorExists) {
        const newInstructors = [...instructors, newInstructor];
        onInstructorsChange(newInstructors);
      }

      setSelectedInstructor("");
    }
  };

  const handleDeleteInstructor = (indexToDelete) => {
    const newInstructors = instructors.filter(
      (_, index) => index !== indexToDelete
    );
    onInstructorsChange(newInstructors);
  };

  // Create a list of available instructors (you might want to pass this as a prop instead)
  const availableInstructors = [
    { name: "محمد أحمد", avatar: "/avatars/instructor1.png" },
    { name: "أحمد محمد", avatar: "/avatars/instructor2.png" },
    // Add more instructors as needed
  ];

  return (
    <div className="personal-info">
      <h5
        className="personal-info-h5"
        style={{
          color: "black",
          fontWeight: "500",
          marginBottom: "20px",
        }}
      >
        {head_title}
      </h5>
      <div className="">
        <div className="p-1 info-1 d-flex align-items-center gap-4 flex-wrap col-lg-12 col-md-4 col-2">
          <div
            className="w-full bg-white rounded-2xl shadow-[0_2px_8px_rgba(0,0,0,0.08)] p-1"
            dir="rtl"
          >
            {/* Header */}
            <div className="flex justify-between items-center mb-4 flex-wrap">
              <div className="relative-input mb-1 ">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onKeyDown={handleKeyDownInstructor}
                  onChange={(e) => setSelectedInstructor(e.target.value)}
                  value={selectedInstructor}
                  
                >
                  <option value="" style={{ display: "none" }}></option>
                  {instructorsAll.map((instructor, index) => (
                    <option
                      key={index}
                      value={`${instructor.name}|${instructor.avatar}`}
                    >
                      {instructor.name}
                    </option>
                  ))}
                </select>
              </div>
              <button
                onClick={addInstructor}
                className="text-[#ff6b00] hover:text-[#ff8533] text-sm flex items-center gap-1 transition-colors duration-200 font-medium skills-btn"
              >
                <span className="text-lg" style={{ fontSize: "25px" }}>
                  +
                </span>
                <span>إضافة</span>
              </button>
            </div>

            {/* Instructors List */}
            <div className="info-parent d-flex flex-wrap col-lg-12 col-md-2 col-2">
              <div
                className="maker d-flex flex-column gap-2"
                style={{ width: "50%" }}
              >
                {instructors.map((instructor, index) => (
                  <div
                    className="d-flex align-items-center p-2 gap-2"
                    key={index}
                  >
                    <img
                      src={require("./../../../../Pages/Dashboard/images/close-circle.png")}
                      alt="delete"
                      onClick={() => handleDeleteInstructor(index)}
                      style={{ cursor: "pointer" }}
                    />
                    <div className="d-flex align-items-center gap-2">
                      <img
                        src={require("./../images/Bright Colorful Young Man Avatar.jpg")} //instructor.avatar
                        alt={instructor.name}
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                      <p
                        style={{
                          margin: "0",
                          fontWeight: "500",
                          color: "black",
                        }}
                      >
                        {instructor.name}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstructorsSection;
