// utils/dateUtils.js
export const dateUtils = {
  // تنسيق التاريخ للعرض في حقل التاريخ
  formatForInput: (dateString) => {
    if (!dateString) return "";

    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return "";
      return date.toISOString().split("T")[0];
    } catch (error) {
      console.error("Error formatting date for input:", error);
      return "";
    }
  },

  // تنسيق التاريخ للعرض للمستخدم
  formatForDisplay: (dateString) => {
    if (!dateString) return "";

    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return "";

      return new Intl.DateTimeFormat("ar-EG", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(date);
    } catch (error) {
      console.error("Error formatting date for display:", error);
      return "";
    }
  },

  // تحويل التاريخ من تنسيق إلى آخر
  parse: (dateString, fromFormat = "YYYY-MM-DD") => {
    if (!dateString) return null;

    try {
      const parts = dateString.split("-");
      if (fromFormat === "YYYY-MM-DD") {
        return new Date(parts[0], parts[1] - 1, parts[2]);
      }
      return null;
    } catch (error) {
      console.error("Error parsing date:", error);
      return null;
    }
  },

  // التحقق من صحة التاريخ
  isValid: (dateString) => {
    if (!dateString) return false;

    const date = new Date(dateString);
    return !isNaN(date.getTime());
  },
};
