import React, { useContext, useEffect, useState } from "react";
import { Navbar, Nav, Form, FormControl } from "react-bootstrap";
import "./TopBar.css";
import axios from "axios";
import Loading from "../Loading/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Menu } from "./../../../Context/MenuContext";
import { WindowWidth } from "./../../../Context/WindowContext";
export default function HeaderTopBar() {
  const [currentUser, setCurrentUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const MyMenu = useContext(Menu);
  const windowWidth = useContext(WindowWidth);
  const Open_Menu = MyMenu.setIsOpen;
  console.log(currentUser);
  //Get Current User
  useEffect(() => {
    try {
      setLoading(true);
      axios.get("/JSON/CurrentUser.json").then((res) => {
        setCurrentUser(res.data);
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
    }
  }, []);
  return (
    <>
      {loading && <Loading />}
      <Navbar
        expand="lg"
        className="HeaderTopBar"
        style={{
          justifyContent:
            windowWidth.width < "768"
              ? "space-evenly"
              : windowWidth.width < "1562"
              ? "space-between"
              : "space-between",
          width:
            windowWidth.width > 768 && windowWidth.width < "1562" ? "110%" : "",
        }}
      >
        <Nav className="right-section">
          <Nav.Link href="/profile" className="user-link">
            <div className="profile">
              <div className="usernames">
                <span className="currentUser-username">
                  {" "}
                  {currentUser.name}
                </span>
                <span className="currentUser-role"> {currentUser.role}</span>
              </div>
              <img
                src={require("./images/Bright Colorful Young Man Avatar.jpg")}
                alt="User"
                className="user-avatar"
              />
            </div>
          </Nav.Link>
          <Nav.Link href="/dashboard/notification" className="icon-link">
            <div></div>
          </Nav.Link>
        </Nav>

        {windowWidth.width < "768" && (
          <div className="d-flex align-items-center gap-5">
            <FontAwesomeIcon
              onClick={() => Open_Menu((prev) => !prev)}
              cursor={"pointer"}
              icon={faBars}
            />
          </div>
        )}
        <Form className="search-form">
          <FormControl
            type="search"
            placeholder="ابحث"
            className="search-input"
          />
          <i className="search-icon"></i>
        </Form>
      </Navbar>
    </>
  );
}
