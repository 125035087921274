import "./Certificates.css";
import DetailsTopBarOperations from "../../../../Components/Dashboard/DetailsTop/DetailsTopBarOperations";
import { Outlet, useLocation, useParams } from "react-router-dom";

export default function Certificates() {
  const location = useLocation();
  const { id } = useParams();
  // تكوين البيانات الديناميكية
  const pagesData = {
    certificates: {
      title: "الشهادات",
      button: "اضف شهادة",
      path: "/dashboard/operationsMangement/certificates",
    },
    attendance: {
      title: "الحضور",
      button: "اضافة سجل حضور",
      path: "/dashboard/operationsMangement/certificates/attendence",
    },
    verification: {
      title: "التحقق من الشهادات",
      button: "تحقق من شهادة",
      path: "/dashboard/operationsMangement/certificates/verification",
    },
  };

  // تحديد الصفحة الحالية
  const getCurrentPage = () => {
    const currentPath = location.pathname;

    // تحقق من المسار بالضبط
    if (currentPath.includes("/attendence")) {
      return pagesData.attendance;
    } else if (currentPath.includes("/verification")) {
      return pagesData.verification;
    } else {
      // إذا كان المسار الرئيسي للشهادات
      return pagesData.certificates;
    }
  };

  const currentPage = getCurrentPage();
  console.log(location.pathname);
  // تكوين الروابط الفرعية
  const subLinks = [
    {
      title: "الشهادات",
      path: pagesData.certificates.path,
    },
    {
      title: "الحضور",
      path: pagesData.attendance.path,
    },
    {
      title: "التحقق من الشهادات",
      path: pagesData.verification.path,
    },
  ];
  const isAddRoute = location.pathname.includes("add");
 
  return (
    <>
      {!id && !isAddRoute ? (
        <div className="trainee">
          {/* certificates.js */}

          <DetailsTopBarOperations
            title={currentPage.title}
            button={currentPage.button}
            icon="plus"
            link={`${currentPage.path}/add`}
            linkParent="ادارة العمليات"
            linkChild={currentPage.title}
            sendSuccess={true}
            subLinks={subLinks}
          />

          <Outlet />
        </div>
      ) : (
        <Outlet />
      )}
    </>
  );
}
