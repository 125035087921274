import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Loading from "../../../../../Components/Dashboard/Loading/Loading";
import axios from "axios";
import { Form } from "react-bootstrap";
import { Upload } from "lucide-react";

import DetailsTopBarUsers from "../../../../../Components/Dashboard/DetailsTop/DetailsTopBarUsers";
import GoalsSection from "../../../../../Components/Dashboard/Helpers/Certificates/GoalsSection ";
import SkillsSection from "../../../../../Components/Dashboard/Helpers/Certificates/SkillsSection ";
import WorkshopsSection from "../../../../../Components/Dashboard/Helpers/Certificates/WorkshopsSection ";
import InstructorsSection from "../../../../../Components/Dashboard/Helpers/Certificates/InstructorsSection";

export default function UpdateCertificate() {
  const [loading, setLoading] = useState(true);
  const [sendSuccess, setSendSuccess] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [form, setForm] = useState({
    id: "",
    training_program: "",
    number_of_trainers: "",
    nuber_of_workshops: "",
    type_of_certificate: "",
    accreditation: "",
    description: "",
    goals: "",
    workshops: "",
    tools: "",
    trainers: "",
    skills: "",
    upload_exam: "",
  });

  const [skills, setSkills] = useState([]);
  const [goals, setGoals] = useState([]);
  const [workshops, setWorkshops] = useState([]);
  const [tools, setTools] = useState([]);
  const [instructors, setinstructorss] = useState([]);
  const [instructorsAll, setinstructorssAll] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    handleFile(file);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get("/JSON/CertificatesContent.json");
        const CertificateData = response.data[id - 1];

        setForm(CertificateData);
        setSkills([...CertificateData.skills]);
        setGoals([...CertificateData.goals]);
        setWorkshops([...CertificateData.workshops]);
        setTools([...CertificateData.tools]);
        setinstructorss([...CertificateData.instructors]);
        setSelectedFile(CertificateData.upload_exam);
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get("/JSON/Instructors.json");
        const CertificateData = response.data;
        setinstructorssAll(CertificateData);
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    handleFile(file);
  };

  const handleFile = (file) => {
    setSelectedFile(file);
    setForm((prev) => ({ ...prev, upload_exam: file }));
  };

  async function handleSubmit(e) {
    e.preventDefault();
    const formElement = e.target;
    if (formElement.checkValidity()) {
      try {
        //API
        // await axios.post(``, form);
        console.log("النموذج تم إرساله");
        setSendSuccess(true);
      } catch (err) {
        console.log(err);
      }
    } else {
      setValidated(true);
      console.log("النموذج يحتوي على أخطاء، لن يتم الإرسال");
    }
  }

  function handleFormChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }
  useEffect(() => {
    if (sendSuccess) {
      navigate("/dashboard/operationsMangement/certificates"); // تغيير المسار حسب الحاجة
    }
  }, [sendSuccess, navigate]);
  console.log(form);
  return (
    <>
      {loading && <Loading />}
      <Form onSubmit={handleSubmit} noValidate validated={validated}>
        <div className="trainee">
          <DetailsTopBarUsers
            title={form.training_program}
            button="حفظ"
            icon="edit"
            backIcon={true}
            link="/dashboard/operationsMangement/certificates"
            linkBack={`/dashboard/operationsMangement/certificates`}
            linkParent="ادارة العمليات"
            linkChild="احد الشهادات"
            name={form.name}
            submitForm={handleSubmit}
            sendSuccess={sendSuccess}
          />

          <div className="personal-info">
            <h5
              className="personal-info-h5"
              style={{ color: "black", fontWeight: "500" }}
            >
              عن الشهادة
            </h5>
            <div className="info-parent d-flex align-items-center justify-content-between flex-wrap col-lg-12 col-md-2 col-2 ">
              <div className="info-1 col-lg-12 col-md-12 col-12">
                <div className="d-flex align-items-center justify-content-between p-2">
                  <div style={{ width: "100%" }}>
                    <div className="mb-3 row">
                      <label
                        htmlFor="inputPassword"
                        className="col-sm-2 col-form-label"
                      >
                        الاعتماد
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          className="form-control"
                          id="inputPassword"
                          onChange={handleFormChange}
                          name="accreditation"
                          value={form.accreditation}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between p-2">
                  <div style={{ width: "100%" }}>
                    <div className="mb-3 row">
                      <label
                        htmlFor="description"
                        className="col-sm-2 col-form-label"
                      >
                        الوصف
                      </label>
                      <div className="col-sm-10">
                        <textarea
                          className="form-control"
                          id="description"
                          rows="5"
                          placeholder="أدخل الوصف هنا"
                          onChange={handleFormChange}
                          name="description"
                          value={form.description}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <GoalsSection
            goals={goals}
            onGoalsChange={(newGoals) => {
              setGoals(newGoals);
              setForm((prev) => ({ ...prev, goals: newGoals }));
            }}
          />
          <SkillsSection
            skills={skills}
            onSkillsChange={(newSkills) => {
              setSkills(newSkills);
              setForm((prev) => ({ ...prev, skills: newSkills }));
            }}
          />
          <WorkshopsSection
            workshops={workshops}
            onWorkshopsChange={(newWorkshops) => {
              setWorkshops(newWorkshops);
              setForm((prev) => ({ ...prev, workshops: newWorkshops }));
            }}
            head_title="الدورات"
          />
          <WorkshopsSection
            workshops={tools}
            onWorkshopsChange={(newTools) => {
              setTools(newTools);
              setForm((prev) => ({ ...prev, tools: newTools }));
            }}
            head_title="الادوات"
          />
          <InstructorsSection
            workshops={instructors}
            onWorkshopsChange={(newinstructors) => {
              setinstructorss(newinstructors);
              setForm((prev) => ({ ...prev, instructors: newinstructors }));
            }}
            head_title="المدربين"
            instructorsAll={instructorsAll}
          />

          <div className="personal-info">
            <h5
              className="personal-info-h5"
              style={{ color: "black", fontWeight: "500" }}
            >
              رفع الامتحان
            </h5>
            <div className="info-parent d-flex align-items-center justify-content-between flex-wrap col-lg-12 col-md-2 col-2">
              <div
                className="w-full mt-4 file-upload  text-center"
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                <div
                  className="cursor-pointer"
                  onClick={() => document.getElementById("fileInput").click()}
                >
                  <div className=" d-flex flex-column p-4 align-items-center justify-content-center">
                    <img
                      src={require("./../../../images/upload_15641804 1.png")}
                      alt="upload icon"
                      style={{
                        width: "100px",
                        height: "64px",
                        marginBottom: "10px",
                      }}
                    />
                    <p
                      style={{
                        color: "#f89523",
                        marginBottom: "5px",
                        fontWeight: "500",
                      }}
                    >
                      اسحب وأفلت الملف هنا
                    </p>
                    <p style={{ color: "#666666", fontSize: "14px" }}>
                      يرجى اختيار ملف بصيغة MP4
                    </p>
                    {selectedFile && (
                      <p
                        style={{
                          marginTop: "10px",
                          fontSize: "14px",
                          color: "#333",
                        }}
                      >
                        تم اختيار:{" "}
                        {typeof selectedFile === "string"
                          ? selectedFile
                          : selectedFile.name}
                      </p>
                    )}
                  </div>
                </div>
                <input
                  id="fileInput"
                  type="file"
                  className="d-none"
                  onChange={handleFileInput}
                  accept=""
                />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
}
