import { Outlet } from "react-router-dom";
import TopBar from "../../Components/Dashboard/TopBar/TopBar";
import "./Dashboard.css"
import SideBar from "../../Components/Dashboard/SideBar/SideBar";
import { WindowWidth } from "./../../Context/WindowContext";
import { useContext } from "react";

export default function Dashboard() {
  const windowWidth = useContext(WindowWidth);
  return (
    <div className="Dashboard">
      <TopBar />
      <div className="d-flex gap-1">
        <SideBar />
        <div
          className={`dashboard-content`}
          style={{ marginTop: windowWidth.width < "768" ? "90px":"0px"}}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
}
