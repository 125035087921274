import React, { useEffect, useState } from "react";
import { Form, FormSelect, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faFilter } from "@fortawesome/free-solid-svg-icons";
import PaginatedItems from "../../../../../Components/Dashboard/Pagination/Pagination";

import Loading from "../../../../../Components/Dashboard/Loading/Loading";
import "./../Certificates.css";
import axios from "axios";
import DropdownMenu from "../../../../../Components/Dashboard/Helpers/DropdownMenu ";

export default function CertificatesContent() {
  const [certificates, setCertificates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(6);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [selectedCertType, setSelectedCertType] = useState(null);
  const [openedDropdown, setOpenedDropdown] = useState(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const header = [
    { key: "id", name: "الكود" },
    { key: "training_program", name: "البرنامج التدريبي" },
    { key: "type_of_certificate", name: "نوع الشهادة" },
    { key: "nuber_of_workshops", name: "عدد ورش العمل" },
    { key: "number_of_trainers", name: "عدد المدربين" },
    { key: "select", name: "" },
  ];

  useEffect(() => {
    try {
      setLoading(true);
      axios.get("/JSON/CertificatesContent.json").then((res) => {
        setCertificates(res.data);
        setTotal(res.data.length);
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isFilterOpen && !event.target.closest(".filter-container")) {
        setIsFilterOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isFilterOpen]);

  const filteredData = certificates.filter((item) => {
    const searchMatch = item.training_program
      .toLowerCase()
      .includes(search.toLowerCase());
    const typeMatch = selectedCertType
      ? item.type_of_certificate === selectedCertType
      : true;
    return searchMatch && typeMatch;
  });

  const dataPaginated = filteredData.slice((page - 1) * limit, page * limit);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const handleCertTypeSelect = (type) => {
    setSelectedCertType(type === selectedCertType ? null : type);
    setPage(1);
  };

  const headersShow = header.map((item, index) => (
    <th
      key={index}
      className={`table-header ${item.key === "id" ? "small-column" : ""}`}
    >
      {item.name}
    </th>
  ));

  const certificatesShow = dataPaginated.map((item, index) => (
    <tr key={index} className="table-row">
      {header.map((headerItem, key2) => (
        <td
          key={key2}
          className={headerItem.key === "id" ? "small-column" : ""}
        >
          {headerItem.key === "select" ? (
            <div className="dropdown-container">
              <FontAwesomeIcon
                onClick={() => {
                  setOpenedDropdown(openedDropdown === index ? null : index);
                }}
                cursor="pointer"
                icon={faEllipsis}
              />
              {openedDropdown === index && (
                <DropdownMenu
                  className="faEllipsis-drop"
                  id={item["id"]}
                  onClose={() => setOpenedDropdown(null)}
                />
              )}
            </div>
          ) : (
            item[headerItem.key]
          )}
        </td>
      ))}
    </tr>
  ));

  const renderFilterDropdown = () => {
    if (!isFilterOpen) return null;

    return (
      <div className="custom-filter-dropdown">
        <div className="filter-title">نوع الشهادة</div>
        <div className="filter-options">
          {["عن بعد", "حضوري", "تعلم الكتروني"].map((type) => (
            <label
              key={type}
              className="filter-option"
              onClick={() => handleCertTypeSelect(type)}
            >
              <div className="radio-circle">
                {selectedCertType === type && (
                  <div className="radio-circle-selected" />
                )}
              </div>
              {type}
            </label>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      {loading && <Loading />}
      <div className="trainee-table">
        <div className="table-header-controls">
          <div className="filter-container">
            <button
              onClick={() => setIsFilterOpen(!isFilterOpen)}
              className={`filter-button ${isFilterOpen ? "active" : ""}`}
            >
              <FontAwesomeIcon icon={faFilter} />
            </button>
            {renderFilterDropdown()}
          </div>
          <div className="search-parent">
            <Form.Control
              className="search-trainee"
              type="search"
              placeholder="ابحث في البرنامج التدريبي"
              onChange={handleSearch}
              value={search}
            />
            <i className="search-icon-trainee"></i>
          </div>
        </div>

        <div className="formtable">
          <Table striped bordered hover className="custom-table">
            <thead>
              <tr>{headersShow}</tr>
            </thead>
            <tbody>{certificatesShow}</tbody>
          </Table>
        </div>

        <div className="pagination-parent">
          <div className="pagination-controls">
            <p style={{ margin: "10px" }}>يوجد</p>
            <FormSelect
              class="form-select"
              id="floatingSelect"
              aria-label="Floating label select example"
              style={{ width: "110px", color: "#f89523" }}
              onChange={(e) => setLimit(e.target.value)}
            >
              <option value="6">6</option>
              <option value="8">8</option>

              <option value="10">10</option>
              <option value="15">15</option>
            </FormSelect>
            <p style={{ margin: "10px" }}>مستخدمين فالصفحة الواحدة</p>
          </div>
          <PaginatedItems
            setPage={setPage}
            itemsPerPage={limit}
            data={filteredData}
            total={total}
          />
        </div>
      </div>
    </>
  );
}
