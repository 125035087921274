import { Button } from "react-bootstrap";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightLong,
  faEdit,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

function DetailsTopBarOperations(props) {
  const navigate = useNavigate();
  const handleNavigate = (e) => {
    if (props.sendSuccess) {
      navigate(props.link);
    }
  };
  const location = useLocation();
 const isPathActive = (path) => {
   if (!path) return false;
   // Remove trailing slash for consistent comparison
   const currentPath = location.pathname.replace(/\/$/, "");
   const linkPath = path.replace(/\/$/, "");
   return currentPath === linkPath;
 };
  return (
    <div>
      <div className="DetailsTopBar">
        <div>
          <div className="links-details">
            <NavLink to="/">
              <img src={require("./../TopBar/images/home-2.png")} alt="" />
            </NavLink>
            {">"}
            <NavLink to="/">
              <p>الصفحة الرئيسية</p>
            </NavLink>
            {">"}

            {props.linkParent && (
              <>
                <NavLink to="#" className="link-item">
                  {props.linkParent}
                </NavLink>

                {props.linkChild && (
                  <>
                    {">"}
                    <NavLink
                      to={`${props.link}`}
                      className="link-item"
                      style={{
                        color: "#F68C1E",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {props.name ? props.name : props.linkChild}
                    </NavLink>
                  </>
                )}
              </>
            )}
          </div>

          <div className="add-trainne-info">
            <h5>
              {props.backIcon && (
                <Link to={`${props.linkBack}`}>
                  {" "}
                  <FontAwesomeIcon
                    icon={faArrowRightLong}
                    style={{ color: "#626264", marginLeft: "10px" }}
                  />
                </Link>
              )}{" "}
              {props.title}
            </h5>
            <Button
              className={`add-trainee-btn ${
                props.button === "تعديل" && "edit-btn"
              } ${props.button === "اضافة المستخدم" && "add-btn"} ${
                props.button === "حفظ" && "save-btn"
              }`}
              type="submit"
              onClick={handleNavigate}
              style={{
                padding:
                  props.button === "تعديل"
                    ? "10px 55px 10px 30px "
                    : props.button === "حفظ"
                    ? "30px 30px"
                    : "",
                border: "none",
              }}
            >
              {props.button === "اضف متدرب جديد" ||
              props.button === "اضف شهادة" ? (
                <>
                  <div style={{ textAlign: "center" }}>
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="add-trainee-btn-i"
                    ></FontAwesomeIcon>{" "}
                    {props.button}
                  </div>
                </>
              ) : props.button === "تعديل" ? (
                <>
                  <div style={{ textAlign: "center" }}>
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="add-trainee-btn-i"
                      style={{ right: "30px" }}
                    ></FontAwesomeIcon>{" "}
                    {props.button}
                  </div>
                </>
              ) : props.button === "اضافة المستخدم" ? (
                <>
                  <div style={{ textAlign: "center" }}>{props.button}</div>
                </>
              ) : props.button === "حفظ" ? (
                <>
                  <div style={{ textAlign: "center" }}>{props.button}</div>
                </>
              ) : (
                ""
              )}
            </Button>
          </div>

          {/* Sub Navigation Links */}
          <div className="sub-nav-links">
            {props.subLinks &&
              props.subLinks.map((link, index) => (
                <NavLink
                  key={index}
                  to={link.path}
                  className={({ isActive }) =>
                    `sub-nav-link ${
                      isPathActive(link.path) ? "active-sub-link" : ""
                    }`
                  }
                  end // Add this prop to ensure exact path matching
                >
                  {link.title}
                </NavLink>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsTopBarOperations;
