import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import mapIcon from "./images/map.png";
import usersIcon from "./images/profile-2user.png";
import TraineesIcon from "./images/people.png";
import InstractorIcon from "./images/user-tag.png";

import DocumentText from "./images/document-text.png";
import DocumentIcon from "./images/document-copy (1).png";
import DeviceMessage from "./images/device-message.png";


export const links = [
  {
    name: "لوحة التحكم",
    path: "/dashboard",
    icon: mapIcon,
  },
  {
    name: "ادارة المستخدمين",
    path: "/dashboard/usersMangement",
    icon: usersIcon,
    hasSubmenu: true,
    submenu: [
      {
        name: "المتدربين",
        path: "/dashboard/usersMangement/trainees",
        icon: TraineesIcon,
      },
      {
        name: "المحاضرين",
        path: "/dashboard/usersMangement/instructors",
        icon: InstractorIcon,
      },
    ],
  },
  {
    name: "ادارة العمليات",
    path: "/dashboard/operationsMangement",
    icon: DeviceMessage,
    hasSubmenu: true,
    submenu: [
      // {
      //   name: "الدورات",
      //   path: "/dashboard/operationsMangement/courses",
      //   icon: DocumentText,
      // },
      {
        name: "الشهادات",
        path: "/dashboard/operationsMangement/certificates",
        icon: DocumentIcon,
      },
    ],
  },
];
