import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../../../Components/Dashboard/Loading/Loading";
import axios from "axios";
import DetailsTopBarUsers from "../../../../../Components/Dashboard/DetailsTop/DetailsTopBarUsers";

export default function ShowCetificate() {
  const [certificate, setCertificates] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  let skillsShow = "";
  let goalsShowfirst = "";
  let goalsShowsecond = "";
  let toolsShowfirst = "";
  let toolsShowsecond = "";
  let workshopsShowfirst = "";
  let workshopsShowsecond = "";
  let trainersShowfirst = "";
  let trainersShowsecond = "";

  //Get  User
  useEffect(() => {
    try {
      setLoading(true);
      axios.get("/JSON/CertificatesContent.json").then((res) => {
        setCertificates(res.data[`${id - 1}`]);
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
    }
  }, []);
  const handlePDFDownload = () => {
    // Implement logic to download PDF here
    window.open(certificate.upload_exam, "_blank");
  };

  if (certificate.skills) {
    skillsShow = certificate.skills.map((item, index) => (
      <div
        style={{
          borderRadius: "35px",
          border: "1px solid #e0e0e0 ",
          padding: "16px",
        }}
        key={index}
      >
        <p
          style={{
            color: "black",
            fontWeight: "500",
            maxWidth: "273px",
            whiteSpace: "normal",
            wordBreak: "break-word",
            marginBottom: "0",
          }}
        >
          {item}
        </p>
      </div>
    ));
  }
  if (certificate.goals) {
    goalsShowfirst = certificate.goals.map((item, index) => {
      if (index < certificate.goals.length / 2) {
        return (
          <div className="d-flex align-items-center p-2 gap-2" key={index}>
            <img
              src={require("./../../../images/tick-circle.png")}
              alt="tick-circle"
            />
            <p style={{ margin: "0", fontWeight: "500", color: "black" }}>
              {item}
            </p>
          </div>
        );
      }
      return null;
    });
    goalsShowsecond = certificate.goals.map((item, index) => {
      if (index >= certificate.goals.length / 2) {
        return (
          <div className="d-flex align-items-center p-2 gap-2" key={index}>
            <img
              src={require("./../../../images/tick-circle.png")}
              alt="tick-circle"
            />
            <p style={{ margin: "0", fontWeight: "500", color: "black" }}>
              {item}
            </p>
          </div>
        );
      }
      return null;
    });
  }
  if (certificate.workshops) {
    workshopsShowfirst = certificate.workshops.map((item, index) => {
      if (index < certificate.workshops.length / 2) {
        return (
          <div className="d-flex align-items-center p-2 gap-2" key={index}>
            <img
              src={require("./../../../images/tick-circle.png")}
              alt="tick-circle"
            />
            <p style={{ margin: "0", fontWeight: "500", color: "black" }}>
              {item}
            </p>
          </div>
        );
      }
      return null;
    });
    workshopsShowsecond = certificate.workshops.map((item, index) => {
      if (index >= certificate.workshops.length / 2) {
        return (
          <div className="d-flex align-items-center p-2 gap-2" key={index}>
            <img
              src={require("./../../../images/tick-circle.png")}
              alt="tick-circle"
            />
            <p style={{ margin: "0", fontWeight: "500", color: "black" }}>
              {item}
            </p>
          </div>
        );
      }
      return null;
    });
  }
  if (certificate.tools) {
    toolsShowfirst = certificate.tools.map((item, index) => {
      if (index < certificate.tools.length / 2) {
        return (
          <div className="d-flex align-items-center p-2 gap-2" key={index}>
            <img
              src={require("./../../../images/tick-circle.png")}
              alt="tick-circle"
            />
            <p style={{ margin: "0", fontWeight: "500", color: "black" }}>
              {item}
            </p>
          </div>
        );
      }
      return null;
    });
    toolsShowsecond = certificate.tools.map((item, index) => {
      if (index >= certificate.tools.length / 2) {
        return (
          <div className="d-flex align-items-center p-2 gap-2" key={index}>
            <img
              src={require("./../../../images/tick-circle.png")}
              alt="tick-circle"
            />
            <p style={{ margin: "0", fontWeight: "500", color: "black" }}>
              {item}
            </p>
          </div>
        );
      }
      return null;
    });
  }
  if (certificate.instructors) {
    trainersShowfirst = certificate.instructors.map((item, index) => {
      console.log(item.avatar);
      if (index < certificate.instructors.length / 2) {
        return (
          <div className="d-flex align-items-center p-2 gap-2" key={index}>
            <img
              style={{ width: "50px", borderRadius: "50%" }}
              src={require("./../../../images/Bright Colorful Young Man Avatar.jpg")}
              alt="tick-circle"
            />
            <p style={{ margin: "0", fontWeight: "500", color: "black" }}>
              {item.name}
            </p>
          </div>
        );
      }
      return null;
    });
    trainersShowsecond = certificate.instructors.map((item, index) => {
      if (index >= certificate.instructors.length / 2) {
        return (
          <div className="d-flex align-items-center p-2 gap-2" key={index}>
            <img
              style={{ width: "50px", borderRadius: "50%" }}
              src={require("./../../../images/Bright Colorful Young Man Avatar.jpg")} //item.avatar
              alt="tick-circle"
            />
            <p style={{ margin: "0", fontWeight: "500", color: "black" }}>
              {item.name}
            </p>
          </div>
        );
      }
      return null;
    });
  }
  return (
    <>
      {loading && <Loading />}
      <div className="trainee">
        <DetailsTopBarUsers
          title={certificate.training_program}
          button="تعديل"
          icon="edit"
          backIcon={true}
          link={`/dashboard/operationsMangement/certificates/edit/${id}`}
          linkBack={`/dashboard/operationsMangement/certificates`}
          linkParent="ادارة العمليات"
          linkChild="احد الشهادات"
          name={"احد الشهادات"}
          sendSuccess={true}
        />
        <div className="personal-info">
          <h5
            className="personal-info-h5"
            style={{ color: "black", fontWeight: "500" }}
          >
            عن الشهادة
          </h5>
          <div className="info-parent d-flex align-items-center justify-content-between flex-wrap col-lg-12 col-md-2 col-2 ">
            <div className="p-4 info-1" style={{ width: "60%" }}>
              <div className="d-flex align-items-center justify-content-between p-2 ">
                <p>الاعتماد</p>
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    padding: "6px 20px",
                  }}
                >
                  {certificate.accreditation}
                </p>
              </div>
            </div>
            <div className="p-4 info-1" style={{ width: "100%" }}>
              <div className="d-flex align-items-baseline justify-content-between p-2 ">
                <p>الوصف</p>
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    padding: "6px 20px",
                    overflow: "auto",
                  }}
                >
                  {certificate.description}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="personal-info">
          <h5
            className="personal-info-h5"
            style={{ color: "black", fontWeight: "500" }}
          >
            الاهداف
          </h5>
          <div className="info-parent d-flex flex-wrap col-lg-12 col-md-2 col-12 ">
            <div
              className="maker d-flex flex-column gap-2"
              style={{ width: "40%" }}
            >
              {goalsShowfirst}
            </div>
            <div
              className="maker d-flex flex-column gap-2"
              style={{ width: "40%" }}
            >
              {goalsShowsecond}
            </div>
          </div>
        </div>
        <div className="personal-info">
          <h5
            className="personal-info-h5"
            style={{
              color: "black",
              fontWeight: "500",
              marginBottom: "20px",
            }}
          >
            المهارات
          </h5>
          <div className="">
            <div className="p-4 info-1 d-flex align-items-center gap-4 flex-wrap col-lg-12 col-md-4 col-2">
              {skillsShow}
            </div>
          </div>
        </div>
        <div className="personal-info">
          <h5
            className="personal-info-h5"
            style={{ color: "black", fontWeight: "500" }}
          >
            ورش العمل
          </h5>
          <div className="info-parent d-flex flex-wrap col-lg-12 col-md-2 col-2 ">
            <div
              className="maker d-flex flex-column gap-2"
              style={{ width: "40%" }}
            >
              {workshopsShowfirst}
            </div>
            <div
              className="maker d-flex flex-column gap-2"
              style={{ width: "40%" }}
            >
              {workshopsShowsecond}
            </div>
          </div>
        </div>
        <div className="personal-info">
          <h5
            className="personal-info-h5"
            style={{ color: "black", fontWeight: "500" }}
          >
            الادوات
          </h5>
          <div className="info-parent d-flex flex-wrap col-lg-12 col-md-2 col-2 ">
            <div
              className="maker d-flex flex-column gap-2"
              style={{ width: "40%" }}
            >
              {toolsShowfirst}
            </div>
            <div
              className="maker d-flex flex-column gap-2"
              style={{ width: "40%" }}
            >
              {toolsShowsecond}
            </div>
          </div>
        </div>
        <div className="personal-info">
          <h5
            className="personal-info-h5"
            style={{ color: "black", fontWeight: "500" }}
          >
            المدربين
          </h5>
          <div className="info-parent d-flex flex-wrap col-lg-12 col-md-2 col-2 ">
            <div
              className="maker d-flex flex-column gap-2"
              style={{ width: "40%" }}
            >
              {trainersShowfirst}
            </div>
            <div
              className="maker d-flex flex-column gap-2"
              style={{ width: "40%" }}
            >
              {trainersShowsecond}
            </div>
          </div>
        </div>
        {/* <div className="personal-info">
          <h5
            className="personal-info-h5"
            style={{ color: "black", fontWeight: "500" }}
          >
            رفع الامتحان
          </h5>
          <div className="info-parent d-flex flex-wrap col-lg-12 col-md-2 col-2 ">
            
            <button
              className="bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded-md flex items-center"
              onClick={handlePDFDownload}
            >
              <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
              
            </button>
          </div>
        </div> */}
      </div>
    </>
  );
}
