// LinksContext.jsx
import { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const Links = createContext("");

export default function LinksContext({ children, links }) {
  // استقبال الـ links كـ prop
  const [linksEntered, setLinksEntered] = useState({
    parent: null,
    child: null,
  });

  const location = useLocation();

  useEffect(() => {
    // نقل findLinkInfo داخل الـ useEffect لتجنب المشاكل
    const findLinkInfo = () => {
      for (const link of links) {
        if (link.hasSubmenu) {
          const submenuItem = link.submenu.find(
            (sub) => sub.path === location.pathname
          );
          if (submenuItem) {
            return {
              parent: link.name,
              child: submenuItem.name,
            };
          }
        } else if (link.path === location.pathname) {
          return {
            parent: link.name,
            child: null,
          };
        }
      }
      return null;
    };

    const linkInfo = findLinkInfo();
    if (linkInfo) {
      setLinksEntered(linkInfo);
    }
  }, [location.pathname, links]);

  const updateLinks = (parentName, childName = null) => {
    setLinksEntered({
      parent: parentName,
      child: childName,
    });
  };

  return (
    <Links.Provider value={{ linksEntered, updateLinks }}>
      {children}
    </Links.Provider>
  );
}
