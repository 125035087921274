import { useNavigate, useParams } from "react-router-dom";

import { useEffect, useRef, useState } from "react";
import Loading from "../../../../Components/Dashboard/Loading/Loading";
import axios from "axios";
import { Form } from "react-bootstrap";

import { Trash2 } from "lucide-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import DetailsTopBarUsers from "../../../../Components/Dashboard/DetailsTop/DetailsTopBarUsers";

export default function AddInstructor() {
  const [loading, setLoading] = useState(true);
  const [sendSuccess, setSendSuccess] = useState(false);
  const navigate = useNavigate(); // استخدام التنقل
  const [validated, setValidated] = useState(false); // لحفظ حالة التحقق من صحة النموذج
  const [form, setForm] = useState({
    name: "",
    job: "",
    avatar: "",
    id: "",
    email: "",
    password: "",
    country: "",
    governorate: "",
    city: "",
    training_path: "",
    phone_number: "",
    average_per_hour: "",
    training_hours: "",
    linkedin: "",
    rate: "",
    cv: "",
    date_of_joining: "",
    skills: "",
    certificates_obtained: "",
    WorkRecord: "",
  });

  const [skills, setSkills] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [certificates, setCertificates] = useState([]);
  const [newCertificate, setNewCertificate] = useState({
    title: "",
    source: "",
    date_obtained: "",
  });
  const [selectedImage, setSelectedImage] = useState(
    "./../../images/Bright Colorful Young Man Avatar.jpg" // صورة افتراضية
  );

  // دالة لاختيار الصورة
  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Get the first file
    if (file) {
      const objectURL = URL.createObjectURL(file); // تحويل الملف إلى URL قابل للاستخدام
      setSelectedImage(objectURL); // تعيين الرابط الجديد
    }
  };
  const openImage = useRef(null);
  function handleOpenImage() {
    openImage.current.click();
  }
  form.avatar = selectedImage;

  const handleAddCertificate = () => {
    if (
      newCertificate.title.trim() &&
      newCertificate.source.trim() &&
      newCertificate.date_obtained
    ) {
      setCertificates([...certificates, newCertificate]);
      setNewCertificate({
        title: "",
        source: "",
        date_obtained: "",
      });
      form.certificates_obtained = certificates;
    }
  };

  const handleDeleteCertificate = (index) => {
    setCertificates(certificates.filter((_, i) => i !== index));
    form.certificates_obtained = certificates;
  };
  form.certificates_obtained = certificates;

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim()) {
      e.preventDefault();
      setSkills([...skills, inputValue.trim()]);
      setInputValue("");
      form.skills = skills;
    }
  };

  const handleAddSkill = () => {
    if (inputValue.trim()) {
      setSkills([...skills, inputValue.trim()]);
      setInputValue("");
      form.skills = skills;
    }
  };
  const handleDeleteSkill = (indexToDelete) => {
    setSkills(skills.filter((_, index) => index !== indexToDelete));
    form.skills = skills;
  };
  form.skills = skills;

  const formatDateForInput = (dateValue) => {
    if (!dateValue) return "";

    try {
      const date = new Date(dateValue);
      if (isNaN(date.getTime())) return ""; // إذا كان التاريخ غير صالح

      return date.toISOString().split("T")[0];
    } catch (error) {
      console.error("خطأ في تنسيق التاريخ:", error);
      return "";
    }
  };
  //Get  User
  useEffect(() => {
    try {
      setLoading(true);
      axios.get("/JSON/Instructors.json").then((res) => {
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
    }
  }, []);
  async function handleSubmit(e) {
    e.preventDefault(); // منع الإرسال التلقائي
    const formElement = e.target; // الحصول على العنصر الخاص بالنموذج
    // التحقق من صحة النموذج قبل الإرسال
    console.log(formElement);
    if (formElement.checkValidity()) {
      try {
        //API
        // await axios.post(``, form);
        console.log("النموذج تم إرساله");
        setSendSuccess(true);
      } catch (err) {
        console.log(err);
      }
    } else {
      setValidated(true); // إذا كانت الحقول غير صالحة، نعرض الأخطاء
      console.log("النموذج يحتوي على أخطاء، لن يتم الإرسال");
    }
  }

  function handleFormChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }
  // التأكد من التوجيه بعد النجاح
  useEffect(() => {
    if (sendSuccess) {
      navigate("/dashboard/usersMangement/instructors"); // تغيير المسار حسب الحاجة
    }
  }, [sendSuccess, navigate]);

  console.log(form);
  return (
    <>
      {loading && <Loading />}
      <Form onSubmit={handleSubmit} noValidate validated={validated}>
        <div className="trainee">
          <DetailsTopBarUsers
            title="المحاضرين"
            button="اضافة المستخدم"
            icon="حفظ"
            backIcon={true}
            link="/dashboard/usersMangement/instructors"
            linkBack={`/dashboard/usersMangement/instructors`}
            linkParent="ادارة المستخدمين"
            linkChild="المحاضرين"
            name={form.name}
            submitForm={handleSubmit}
            sendSuccess={sendSuccess}
          />

          <div className="show-trainee-avatar">
            <div className="name-trainee w-100">
              <div
                className="relative inline-block"
                style={{ width: "72px", height: "72px", cursor: "pointer" }}
              >
                <div
                  className={`w-32 h-32 rounded-full bg-gray-300 cursor-pointer flex items-center justify-center transition-colors duration-300 ${
                    selectedImage
                      ? "bg-green-500 hover:bg-green-600"
                      : "bg-gray-300 hover:bg-gray-400"
                  }`}
                  onClick={handleOpenImage}
                  style={{ position: "relative" }}
                >
                  {selectedImage ? (
                    <>
                      <img
                        src={
                          selectedImage ===
                          "./../../images/Bright Colorful Young Man Avatar.jpg"
                            ? require("./../../images/Bright Colorful Young Man Avatar.jpg")
                            : selectedImage
                        }
                        alt="Uploaded"
                        className="w-full h-full object-cover rounded-full"
                        style={{ filter: "brightness(50%)" }}
                      ></img>
                      <span
                        style={{
                          position: "absolute",
                          width: "40px",
                          height: "40px",
                          backgroundColor: "#FEEFDE",
                          top: "calc(50% - 20px)",
                          right: "calc(50% - 20px)",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <FontAwesomeIcon
                          icon={faEdit}
                          style={{
                            textAlign: "center",
                            fontSize: "20px",
                            marginLeft: "3px",
                            color: "#F89523",
                          }}
                        />
                      </span>
                    </>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-10 w-10 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      />
                    </svg>
                  )}
                </div>
                <Form.Group className="mb-3" controlId="image">
                  <Form.Control
                    onChange={handleImageChange}
                    ref={openImage}
                    multiple
                    hidden
                    type="file"
                  />
                </Form.Group>
              </div>
              <div className="text-end d-flex flex-column flex-lg-row col-12 justify-content-between mt-3">
                {/* اسم */}
                <Form.Group
                  className="mb-2 p-2 col-lg-6 col-md-12 col-12"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label style={{ fontWeight: "500" }}>
                    الاسم<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    onChange={handleFormChange}
                    value={form.name}
                    name="name"
                    type="text"
                    placeholder=""
                    required
                  />
                </Form.Group>

                {/* البريد الالكتروني */}
                <Form.Group
                  className="mb-2 p-2 col-lg-6 col-md-12 col-12"
                  controlId="exampleForm.ControlInput2"
                  required
                >
                  <Form.Label style={{ fontWeight: "500" }}>
                    البريد الالكتروني <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    value={form.email}
                    type="email"
                    placeholder=""
                    name="email"
                    onChange={handleFormChange}
                    required
                  />
                </Form.Group>
              </div>
              <div className="info-1 col-lg-12 col-md-12 col-12">
                <div className="d-flex align-items-center justify-content-between p-2">
                  <div style={{ width: "100%" }}>
                    <Form.Label style={{ fontWeight: "500" }} htmlFor="text3">
                      كلمة المرور
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Control
                      onChange={handleFormChange}
                      value={form.password}
                      name="password"
                      type="password"
                      placeholder=""
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="personal-info">
            <h5
              className="personal-info-h5"
              style={{ color: "black", fontWeight: "500" }}
            >
              المعلومات الشخصية
            </h5>
            <div className="info-parent d-flex flex-wrap justify-content-between">
              <div className="info-1 col-lg-6 col-md-12 col-12">
                <div className="d-flex align-items-center justify-content-between p-2 w-100">
                  <div style={{ width: "100%" }}>
                    <Form.Label style={{ fontWeight: "500" }} htmlFor="text7">
                      رقم الهاتف
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Control
                      style={{ width: "100%" }}
                      type="text"
                      id="text7"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      value={form.phone_number}
                      required
                      onChange={handleFormChange}
                      name="phone_number"
                    />
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-between p-2">
                  <div style={{ width: "100%" }}>
                    <Form.Label style={{ fontWeight: "500" }} htmlFor="text4">
                      المدينة
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Select
                      id="text4"
                      required
                      onChange={handleFormChange}
                      value={form.city}
                      name="city"
                      aria-label="Default select example"
                    >
                      <option
                        style={{ display: "none" }}
                        value=""
                        disabled
                      ></option>{" "}
                      {/* قائمة الخيارات المتاحة */}
                      {["المنزلة", "المنصورة", "اكتوبر"].map(
                        (option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        )
                      )}
                    </Form.Select>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between p-2"></div>
              </div>
              <div className="info-1 col-lg-6 col-md-12 col-12">
                <div className="d-flex align-items-center justify-content-between p-2">
                  <div style={{ width: "100%" }}>
                    <Form.Label style={{ fontWeight: "500" }} htmlFor="text6">
                      الدولة
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Select
                      id="text6"
                      required
                      onChange={handleFormChange}
                      value={form.country}
                      name="country"
                      aria-label="Default select example"
                    >
                      <option
                        style={{ display: "none" }}
                        value=""
                        disabled
                      ></option>{" "}
                      {/* قائمة الخيارات المتاحة */}
                      {["مصر", "السعودية"].map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between p-2">
                  <div style={{ width: "100%" }}>
                    <Form.Label style={{ fontWeight: "500" }} htmlFor="text3">
                      المحافظة
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Select
                      id="text3"
                      required
                      onChange={handleFormChange}
                      value={form.governorate}
                      name="governorate"
                      aria-label="Default select example"
                    >
                      <option
                        style={{ display: "none" }}
                        value=""
                        disabled
                      ></option>{" "}
                      {/* قائمة الخيارات المتاحة */}
                      {["الجيزة", "الدقهلية", "القاهرة"].map(
                        (option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        )
                      )}
                    </Form.Select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="personal-info">
            <h5
              className="personal-info-h5"
              style={{ color: "black", fontWeight: "500" }}
            >
              المعلومات المهنية
            </h5>
            <div className="info-parent d-flex align-items-center justify-content-between flex-wrap col-lg-12 col-md-2 col-2 ">
              <div
                className="info-1 col-lg-6 col-md-12 col-12"
                style={{ marginTop: "-15px" }}
              >
                <div className="d-flex align-items-center justify-content-between p-2">
                  <div style={{ width: "100%" }}>
                    <Form.Label style={{ fontWeight: "500" }} htmlFor="text16">
                      المسار التدريبي
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Select
                      id="text16"
                      required
                      onChange={handleFormChange}
                      value={form.training_path}
                      name="training_path"
                      aria-label="Default select example"
                    >
                      <option
                        style={{ display: "none" }}
                        value=""
                        disabled
                      ></option>{" "}
                      {[
                        "التطوير المؤسسي",
                        "ادارة المواهب",
                        "التحول الرقمي",
                      ].map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between p-2">
                  <div style={{ width: "100%" }}>
                    <Form.Label style={{ fontWeight: "500" }} htmlFor="text14">
                      رابط لينكدان <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Control
                      style={{ width: "100%" }}
                      type="text"
                      id="text14"
                      onChange={handleFormChange}
                      name="linkedin"
                      value={form.linkedin}
                      required
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between p-2">
                  <div style={{ width: "100%" }}>
                    <Form.Label style={{ fontWeight: "500" }} htmlFor="text20">
                      التقييم <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Control
                      style={{ width: "100%" }}
                      type="text"
                      id="text20"
                      onChange={handleFormChange}
                      name="rate"
                      value={form.rate}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="info-1 col-lg-6 col-md-12 col-12">
                <div className="d-flex align-items-center justify-content-between p-2">
                  <div style={{ width: "100%" }}>
                    <Form.Label style={{ fontWeight: "500" }} htmlFor="text13">
                      الوظيفة
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Control
                      style={{ width: "100%" }}
                      type="text"
                      id="text13"
                      onChange={handleFormChange}
                      name="job"
                      value={form.job}
                      required
                    />
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-between p-2">
                  <div style={{ width: "100%" }}>
                    <Form.Label style={{ fontWeight: "500" }} htmlFor="text12">
                      تاريخ الانضمام
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Control
                      type="date" // تحديد نوع الحقل كتاريخ
                      id="text12"
                      required
                      name="date_of_job"
                      value={formatDateForInput(form.date_of_job)} // قيمة التاريخ القادمة من form.date_of_job
                      onChange={handleFormChange} // تأكد من إضافة الدالة المناسبة لتحديث القيمة عند التغيير
                      aria-label="Default select example"
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between p-2">
                  <div style={{ width: "100%" }}>
                    <Form.Label style={{ fontWeight: "500" }} htmlFor="text22">
                      ساعات التدريس <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Control
                      style={{ width: "100%" }}
                      type="text"
                      id="text22"
                      onChange={handleFormChange}
                      name="training_hours"
                      value={form.training_hours}
                      required
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between p-2"></div>
              </div>
              <div className="info-1 col-lg-12 col-md-12 col-12">
                <div className="d-flex align-items-center justify-content-between p-2">
                  <div style={{ width: "100%" }}>
                    <Form.Label style={{ fontWeight: "500" }} htmlFor="text23">
                      معدل الاجر في الساعة{" "}
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Control
                      style={{ width: "100%" }}
                      type="text"
                      id="text23"
                      onChange={handleFormChange}
                      name="average_per_hour"
                      value={form.average_per_hour}
                      required
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between p-2">
                  <div style={{ width: "100%" }}>
                    <Form.Label style={{ fontWeight: "500" }} htmlFor="text24">
                      السيرة الذاتية <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Control
                      style={{ width: "100%" }}
                      type="text"
                      id="text24"
                      onChange={handleFormChange}
                      name="cv"
                      value={form.cv}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="personal-info">
            <h5
              className="personal-info-h5"
              style={{
                color: "black",
                fontWeight: "500",
                marginBottom: "20px",
              }}
            >
              المهارات
            </h5>
            <div className="">
              <div className="p-1 info-1 d-flex align-items-center gap-4 flex-wrap col-lg-12 col-md-4 col-2">
                <div
                  className="w-full bg-white rounded-2xl shadow-[0_2px_8px_rgba(0,0,0,0.08)] p-1"
                  dir="rtl"
                >
                  {/* Header */}
                  <div className="flex justify-between items-center mb-4 flex-wrap">
                    <div className="relative-input mb-1 ">
                      <input
                        type="text"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder="اضف مهارة اخرى... "
                        className="w-full px-4 py-3 text-[14px] bg-gray-50 border border-gray-100 rounded-xl focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-gray-200 transition-all duration-200 placeholder:text-gray-400"
                      />
                    </div>
                    <button
                      onClick={handleAddSkill}
                      className="text-[#ff6b00] hover:text-[#ff8533] text-sm flex items-center gap-1 transition-colors duration-200 font-medium skills-btn"
                    >
                      <span className="text-lg" style={{ fontSize: "25px" }}>
                        +
                      </span>
                      <span>إضافة</span>
                    </button>
                  </div>

                  {/* Skills List */}
                  <div className="flex flex-wrap gap-2">
                    {skills.map((skill, index) => (
                      <div
                        key={index}
                        className="group flex items-center gap-2 bg-[#EEF2FF] hover:bg-[#E5EDFF] text-[#4F46E5]  rounded-xl text-sm transition-colors duration-200 skills-delete "
                      >
                        <span className="font-medium">{skill}</span>
                        <button
                          onClick={() => handleDeleteSkill(index)}
                          className="text-[#4F46E5] hover:text-red-500 transition-colors duration-200 skills-delete-btn"
                          aria-label="حذف المهارة"
                        >
                          <Trash2 size={15} />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="personal-info">
            <h5
              className="personal-info-h5"
              style={{
                color: "black",
                fontWeight: "500",
                marginBottom: "20px",
              }}
            >
              الشهادات الحاصل عليها
            </h5>
            <div className="">
              <div className="certificates-container" dir="rtl">
                <div className="input-grid">
                  <div className="input-group">
                    <label>
                      اسم الشهادة <span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                      type="text"
                      value={newCertificate.title}
                      onChange={(e) =>
                        setNewCertificate({
                          ...newCertificate,
                          title: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="input-group">
                    <label>
                      جهة الاعتماد <span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                      type="text"
                      value={newCertificate.source}
                      onChange={(e) =>
                        setNewCertificate({
                          ...newCertificate,
                          source: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="input-group">
                    <label>
                      تاريخ الحصول عليها{" "}
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                      type="date"
                      value={newCertificate.date_obtained}
                      onChange={(e) =>
                        setNewCertificate({
                          ...newCertificate,
                          date_obtained: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="certificates-header">
                    <button
                      onClick={handleAddCertificate}
                      className="add-button"
                    >
                      <span>+</span>
                      <span>إضافة</span>
                    </button>
                  </div>
                </div>

                <div className="certificates-table">
                  <table>
                    <thead>
                      <tr></tr>
                    </thead>
                    <tbody>
                      {certificates.map((cert, index) => (
                        <tr key={index}>
                          <td>{cert.date_obtained}</td>
                          <td>{cert.source}</td>
                          <td>{cert.title}</td>

                          <td>
                            <button
                              onClick={() => handleDeleteCertificate(index)}
                              className="delete-button"
                            >
                              <Trash2 size={18} />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
}
