import { useEffect, useState } from "react";
import Loading from "../../../../../Components/Dashboard/Loading/Loading";
import { Form } from "react-bootstrap";
import DetailsTopBarUsers from "../../../../../Components/Dashboard/DetailsTop/DetailsTopBarUsers";
import InstructorsSection from "../../../../../Components/Dashboard/Helpers/Certificates/InstructorsSection";
import axios from "axios";
import GoalsSection from "../../../../../Components/Dashboard/Helpers/Certificates/GoalsSection ";
import SkillsSection from "../../../../../Components/Dashboard/Helpers/Certificates/SkillsSection ";
import WorkshopsSection from "../../../../../Components/Dashboard/Helpers/Certificates/WorkshopsSection ";
import { useNavigate } from "react-router-dom";

export default function AddCertificate() {
  const [loading, setLoading] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();

  const [form, setForm] = useState({
    id: "",
    training_program: "",
    number_of_trainers: "",
    nuber_of_workshops: "",
    type_of_certificate: "",
    accreditation: "",
    description: "",
    goals: [],
    workshops: [],
    tools: [],
    trainers: [],
    skills: [],
    upload_exam: null,
  });

  const [skills, setSkills] = useState([]);
  const [goals, setGoals] = useState([]);
  let [workshops, setWorkshops] = useState([]);
  let [tools, setTools] = useState([]);
  const [instructors, setinstructorss] = useState([]);
  const [instructorsAll, setinstructorssAll] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    handleFile(file);
  };
  const handleFile = (file) => {
    setSelectedFile(file);
    setForm((prev) => ({ ...prev, upload_exam: file }));
  };
  const handleFileInput = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setForm((prev) => ({ ...prev, upload_exam: file }));
    handleFile(file);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    const formElement = e.target;
    if (formElement.checkValidity()) {
      try {
        // أرسل البيانات الجديدة إلى API
        // await axios.post(`/api/certificates`, form); // قم بتحديث URL الـ API
        console.log("تم إضافة الشهادة بنجاح");
        setSendSuccess(true);
      } catch (err) {
        console.log("خطأ أثناء الإضافة:", err);
      }
    } else {
      setValidated(true);
      console.log("النموذج يحتوي على أخطاء، لن يتم الإرسال");
    }
  }

  function handleFormChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get("/JSON/Instructors.json");
        const CertificateData = response.data;
        setinstructorssAll(CertificateData);
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  // التأكد من التوجيه بعد النجاح
  useEffect(() => {
    if (sendSuccess) {
      navigate("/dashboard/operationsMangement/certificates"); // تغيير المسار حسب الحاجة
    }
  }, [sendSuccess, navigate]);
  console.log(form);
  return (
    <>
      {loading && <Loading />}
      <Form onSubmit={handleSubmit} noValidate validated={validated}>
        <div className="trainee">
          <DetailsTopBarUsers
            title="إضافة شهادة جديدة"
            button="اضافة شهادة"
            icon="add"
            backIcon={true}
            link="/dashboard/operationsMangement/certificates"
            linkBack={`/dashboard/operationsMangement/certificates`}
            linkParent="إدارة العمليات"
            linkChild="إضافة شهادة"
            submitForm={handleSubmit}
            sendSuccess={sendSuccess}
          />

          <div className="personal-info">
            <h5
              className="personal-info-h5"
              style={{ color: "black", fontWeight: "500" }}
            >
              عن الشهادة
            </h5>
            <div className="info-parent d-flex align-items-center justify-content-between flex-wrap col-lg-12 col-md-2 col-2">
              <div className="info-1 col-lg-12 col-md-12 col-12">
                <div className="d-flex align-items-center justify-content-between p-2">
                  <div style={{ width: "100%" }}>
                    <div className="mb-3 row">
                      <label
                        htmlFor="accreditation"
                        className="col-sm-2 col-form-label"
                      >
                        الاعتماد
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          className="form-control"
                          id="accreditation"
                          onChange={handleFormChange}
                          name="accreditation"
                          value={form.accreditation}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between p-2">
                  <div style={{ width: "100%" }}>
                    <div className="mb-3 row">
                      <label
                        htmlFor="description"
                        className="col-sm-2 col-form-label"
                      >
                        الوصف
                      </label>
                      <div className="col-sm-10">
                        <textarea
                          className="form-control"
                          id="description"
                          rows="5"
                          placeholder="أدخل الوصف هنا"
                          onChange={handleFormChange}
                          name="description"
                          value={form.description}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <GoalsSection
            goals={goals}
            onGoalsChange={(newGoals) => {
              setGoals(newGoals);
              setForm((prev) => ({ ...prev, goals: newGoals }));
            }}
          />
          <SkillsSection
            skills={skills}
            onSkillsChange={(newSkills) => {
              setSkills(newSkills);
              setForm((prev) => ({ ...prev, skills: newSkills }));
            }}
          />
          <WorkshopsSection
            workshops={workshops}
            onWorkshopsChange={(newWorkshops) => {
              setWorkshops(newWorkshops);
              setForm((prev) => ({ ...prev, workshops: newWorkshops }));
            }}
            head_title="الدورات"
            empty={true}
          />
          <WorkshopsSection
            workshops={tools}
            onWorkshopsChange={(newTools) => {
              setTools(newTools);
              setForm((prev) => ({ ...prev, tools: newTools }));
            }}
            head_title="الأدوات"
            empty={true}
            toolsSelected={true}
          />
          <InstructorsSection
            workshops={instructors}
            onWorkshopsChange={(newinstructors) => {
              setinstructorss(newinstructors);
              setForm((prev) => ({ ...prev, instructors: newinstructors }));
            }}
            head_title="المدربين"
            instructorsAll={instructorsAll}
          />

          <div className="personal-info">
            <h5
              className="personal-info-h5"
              style={{ color: "black", fontWeight: "500" }}
            >
              رفع الامتحان
            </h5>
            <div className="info-parent d-flex align-items-center justify-content-between flex-wrap col-lg-12 col-md-2 col-2">
              <div
                className="w-full mt-4 file-upload text-center"
                onClick={() => document.getElementById("fileInput").click()}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                <div className="d-flex flex-column p-4 align-items-center justify-content-center">
                  <img
                    src={require("./../../../images/upload_15641804 1.png")}
                    alt="upload icon"
                    style={{
                      width: "100px",
                      height: "64px",
                      marginBottom: "10px",
                    }}
                  />
                  <p
                    style={{
                      color: "#f89523",
                      marginBottom: "5px",
                      fontWeight: "500",
                    }}
                  >
                    اختر ملف
                  </p>
                  {selectedFile && (
                    <p
                      style={{
                        marginTop: "10px",
                        fontSize: "14px",
                        color: "#333",
                      }}
                    >
                      تم اختيار: {selectedFile.name}
                    </p>
                  )}
                </div>
                <input
                  id="fileInput"
                  type="file"
                  className="d-none"
                  onChange={handleFileInput}
                  accept=""
                  
                />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
}
