import { useParams } from "react-router-dom";

import { useEffect, useState } from "react";
import Loading from "../../../../Components/Dashboard/Loading/Loading";
import axios from "axios";
import { Table } from "react-bootstrap";
import DetailsTopBarUsers from "../../../../Components/Dashboard/DetailsTop/DetailsTopBarUsers";

export default function ShowTrainee() {
  const [trainee, setTrainee] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  let subscribedCertificatesShow = "";
  let subscribtionRecordShow = "";

  //Get  User
  useEffect(() => {
    try {
      setLoading(true);
      axios.get("/JSON/Trainees.json").then((res) => {
        setTrainee(res.data[`${id - 1}`]);
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
    }
  }, []);
  const header = [
    {
      key: "program_code",
      name: " كود البرنامج التدريبي /الفعالية",
    },
    {
      key: "certificate_recived",
      name: "استلام الشهادة",
    },
    {
      key: "degree",
      name: "الدرجة",
    },
    {
      key: "attendence",
      name: "الحضور",
    },
    {
      key: "status",
      name: "الحالة",
    },
  ];
  const headersShow = header.map((item, index) => (
    <th
      style={{ textAlign: "center" }}
      className={`table-header ${
        item.key === "status" || item.key === "program_code"
          ? "name-column"
          : "small-column"
      }`}
      key={index}
    >
      {item.name}
    </th>
  ));

  if (trainee.subscribed_certificates) {
    subscribedCertificatesShow = trainee.subscribed_certificates.map(
      (item, index) => (
        <div
          style={{
            borderRadius: "16px",
            border: "1px solid #e0e0e0 ",
            padding: "16px",
          }}
          key={index}
        >
          <div
            className="subscribedCertificates-img"
            style={{
              marginBottom: "10px",
              textAlign: "center",
              maxWidth: "307px",
              wordBreak: "break-word",
              whiteSpace: "normal",
            }}
          >
            <img
              style={{ borderRadius: "16px" }}
              //  from API
              src={require("./../../images/ff32b8a59c9dd17740e99dd82b045b45.png")}
              alt=""
            ></img>
          </div>
          <p
            style={{
              color: "black",
              fontWeight: "500",
              maxWidth: "273px",
              whiteSpace: "normal",
              wordBreak: "break-word",
            }}
          >
            {item.title}
          </p>
        </div>
      )
    );
  }
  console.log(trainee.subscriptionRecord);
  if (trainee.subscriptionRecord) {
    subscribtionRecordShow = trainee.subscriptionRecord.map((item, index) => (
      <tr key={index} style={{ width: "100%", textAlign: "center" }}>
        {header.map((item2, key2) => (
          <td
            className={`table-header ${
              item2.key === "status" || item2.key === "program_code"
                ? "name-column"
                : "small-column"
            }`}
            style={{ overflow: "auto", maxWidth: "200px" }}
            key={key2}
          >
            {item2.key === "degree" ? (
              <div
                style={{
                  color:
                    item[item2.key] > 70
                      ? "#00C973"
                      : item[item2.key] > 30
                      ? "orange"
                      : "red",
                }}
              >
                {item[item2.key]}%
              </div>
            ) : item2.key === "status" ? (
              <div
                style={{
                  backgroundColor:
                    item[item2.key] === "تم الانتهاء منه" ? "#00C973" : "red",
                  color: "white",
                  borderRadius: "14px",
                  padding: "5px 0px",
                }}
              >
                {item[item2.key]}
              </div>
            ) : (
              item[item2.key]
            )}
          </td>
        ))}
      </tr>
    ));
  }
  console.log(id);
  return (
    <>
      {loading && <Loading />}
      <div className="trainee">
        <DetailsTopBarUsers
          title="المتدربين"
          button="تعديل"
          icon="edit"
          backIcon={true}
          link={`/dashboard/usersMangement/trainees/edit/${id}`}
          linkBack={`/dashboard/usersMangement/trainees`}
          linkParent="ادارة المستخدمين"
          linkChild="المتدربين"
          name={trainee.name}
          sendSuccess={true}
        />
        <div className="show-trainee-avatar">
          <img
            // trainee image from API
            src={require("./../../images/Bright Colorful Young Man Avatar.jpg")}
            alt=""
          ></img>
          <div className="name-trainee">
            <p style={{ color: "black", fontWeight: "500" }}>{trainee.name}</p>
            <p>{trainee.email}</p>
          </div>
        </div>
        <div className="personal-info">
          <h5
            className="personal-info-h5"
            style={{ color: "black", fontWeight: "500" }}
          >
            المعلومات الشخصية
          </h5>
          <div className="info-parent d-flex align-items-center justify-content-between flex-wrap col-lg-12 col-md-2 col-2 ">
            <div className="p-4 info-1" style={{ width: "40%" }}>
              <div className="d-flex align-items-center justify-content-between p-2 w-100">
                <p>الحالة</p>
                <p
                  style={{
                    backgroundColor:
                      trainee.status === "مفعل" ? "#B0EED4" : "#F4BCBC",
                    color: trainee.status === "مفعل" ? "#00C973" : "#DC2626",
                    padding: "6px 20px",
                    borderRadius: "80px",
                    fontWeight: "500",
                  }}
                >
                  {trainee.status}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between p-2">
                <p>الجنس</p>
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    padding: "6px 20px",
                  }}
                >
                  {trainee.sex}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between p-2">
                <p>رقم الهاتف</p>
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    padding: "6px 20px",
                  }}
                >
                  {trainee.phone_number}
                </p>
              </div>
            </div>
            <div className="info-1 p-4" style={{ width: "40%" }}>
              <div className="d-flex align-items-center justify-content-between p-2">
                <p>الدولة</p>
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    padding: "6px 20px",
                  }}
                >
                  {trainee.country}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between p-2">
                <p>المدينة</p>
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    padding: "6px 20px",
                  }}
                >
                  {trainee.city}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between p-2">
                <p>تاريخ الميلاد</p>
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    padding: "6px 20px",
                  }}
                >
                  {trainee.date_of_birth}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="personal-info">
          <h5
            className="personal-info-h5"
            style={{ color: "black", fontWeight: "500" }}
          >
            المعلومات المهنية
          </h5>
          <div className="info-parent d-flex align-items-center justify-content-between flex-wrap col-lg-12 col-md-2 col-2 ">
            <div className="p-4 info-1" style={{ width: "40%" }}>
              <div className="d-flex align-items-center justify-content-between p-2 w-100">
                <p>سنين الخبرة في مجال الموارد البشرية</p>
                <p
                  style={{
                    backgroundColor:
                      trainee.experience !== "خارج المجال"
                        ? "#B0E8F2"
                        : "#F4BCBC",
                    color:
                      trainee.experience !== "خارج المجال"
                        ? "#00B5D4"
                        : "#DC2626",
                    padding: "6px 20px",
                    borderRadius: "80px",
                    fontWeight: "500",
                  }}
                >
                  {trainee.experience}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between p-2">
                <p>الوظيفة</p>
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    padding: "6px 20px",
                  }}
                >
                  {trainee.job_professional}
                </p>
              </div>
            </div>
            <div className="info-1 p-4" style={{ width: "40%" }}>
              <div className="d-flex align-items-center justify-content-between p-2">
                <p>رابط لينكدان</p>
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    padding: "6px 20px",
                  }}
                >
                  {trainee.linkedin}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between p-2">
                <p>المدينة</p>
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    padding: "6px 20px",
                  }}
                >
                  {trainee.city}
                </p>
              </div>
            </div>
          </div>
        </div>
        <>
          {trainee.subscribed_certificates === false &&
          trainee.subscriptionRecord === false ? (
            <>
              <div className="personal-info">
                <h5
                  className="personal-info-h5"
                  style={{ color: "black", fontWeight: "500" }}
                >
                  الشهادات المشترك بها
                </h5>
                <div className="d-flex align-items-center justify-content-center flex-column">
                  <img
                    //  from API
                    src={require("./../../images/online-course_3829175 1.png")}
                    alt=""
                  ></img>
                  <h5
                    style={{
                      fontWeight: "500",
                      color: "black",
                      marginTop: "20px",
                    }}
                  >
                    لم يقم بالتسجيل في اي شهادة بعد
                  </h5>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="personal-info">
                <h5
                  className="personal-info-h5"
                  style={{
                    color: "black",
                    fontWeight: "500",
                    marginBottom: "20px",
                  }}
                >
                  البرامج التدريبية قيد الدراسة
                </h5>
                <div className="">
                  <div className="p-4 info-1 d-flex align-items-center gap-4 flex-wrap col-lg-12 col-md-4 col-2">
                    {subscribedCertificatesShow}
                  </div>
                </div>
              </div>
              <div className="personal-info">
                <h5
                  className="personal-info-h5"
                  style={{ color: "black", fontWeight: "500" }}
                >
                  سجل الاشتراكات
                </h5>
                <div className="">
                  <div className="formtable p-4 info-1 d-flex align-items-center gap-4 flex-wrap col-lg-12 col-md-4 col-2">
                    <Table
                      striped
                      bordered
                      hover
                      className="custom-table-ShowTrainee"
                    >
                      <thead className="custom-thead">
                        <tr>
                          <div
                            style={{
                              border: "1px solid #e0e0e0",
                              borderRadius: "50px",
                              overflow: "hidden",
                            }}
                          >
                            {headersShow}
                          </div>
                        </tr>
                      </thead>
                      <tbody>
                        <div
                          style={{
                            border: "1px solid #e0e0e0",
                            borderRadius: "30px",
                            overflow: "hidden",
                            padding: "16px",
                            marginTop: "10px",
                          }}
                        >
                          {subscribtionRecordShow}
                        </div>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      </div>
    </>
  );
}
