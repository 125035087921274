import { useParams } from "react-router-dom";

import { useEffect, useState } from "react";
import Loading from "../../../../Components/Dashboard/Loading/Loading";
import axios from "axios";
import { Modal, Table } from "react-bootstrap";
import DetailsTopBarUsers from "../../../../Components/Dashboard/DetailsTop/DetailsTopBarUsers";

export default function ShowInstructor() {
  const [instructor, setinstructor] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAttendanceModal, setShowAttendanceModal] = useState(false);
  const [selectedAttendance, setSelectedAttendance] = useState(null);
  const { id } = useParams();
  let skillsShow = "";
  let certificatesObtainedShow = "";
  let WorkRecordShow = "";

  //Get  User
  useEffect(() => {
    try {
      setLoading(true);
      axios.get("/JSON/Instructors.json").then((res) => {
        setinstructor(res.data[`${id - 1}`]);
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
    }
  }, []);
  const header = [
    {
      key: "title",
      name: "اسم الشهادة",
    },
    {
      key: "source",
      name: "جهة الاعتماد",
    },
    {
      key: "date_obtained",
      name: "تاريخ الحصول عليها",
    },
  ];
  const headerOD = [
    {
      key: "code",
      name: "الكود",
    },
    {
      key: "training_path",
      name: "المسار التدريبي",
    },
    {
      key: "training_program",
      name: "البرنامج التدريبي",
    },
    {
      key: "number_of_training",
      name: "عدد مرات التدريب",
    },
    {
      key: "nuber_of_hours",
      name: "عدد الساعات",
    },
    {
      key: "date_of_training",
      name: "تاريخ التدريب ",
    },
    {
      key: "attendence",
      name: "",
    },
  ];
  const attendanceHeader = [
    {
      key: "code",
      name: "الكود",
    },
    {
      key: "name",
      name: "الاسم",
    },
    {
      key: "experience",
      name: "الرتبة",
    },
  ];
  const headersShow = header.map((item, index) => (
    <th style={{ textAlign: "center" }} className={`table-header `} key={index}>
      {item.name}
    </th>
  ));
  const headersODShow = headerOD.map((item, index) => (
    <th
      style={{ textAlign: "center", minWidth: "145px" }}
      className={`table-header-OD `}
      key={index}
    >
      {item.name}
    </th>
  ));

  if (instructor.skills) {
    skillsShow = instructor.skills.map((item, index) => (
      <div
        style={{
          borderRadius: "35px",
          border: "1px solid #e0e0e0 ",
          padding: "16px",
        }}
        key={index}
      >
        <p
          style={{
            color: "black",
            fontWeight: "500",
            maxWidth: "273px",
            whiteSpace: "normal",
            wordBreak: "break-word",
            marginBottom: "0",
          }}
        >
          {item}
        </p>
      </div>
    ));
  }
  console.log(instructor.certificates_obtained);
  if (instructor.certificates_obtained) {
    certificatesObtainedShow = instructor.certificates_obtained.map(
      (item, index) => (
        <tr
          key={index}
          style={{
            width: "100%",
            textAlign: "center",

            display: "flex",
            justifyContent: "space-between",
            border: "0",
          }}
        >
          {header.map((item2, key2) => (
            <td
              className={`table-header `}
              style={{ overflow: "auto", maxWidth: "200px" }}
              key={key2}
            >
              {item2.key === "degree" ? (
                <div
                  style={{
                    color:
                      item[item2.key] > 70
                        ? "#00C973"
                        : item[item2.key] > 30
                        ? "orange"
                        : "red",
                  }}
                >
                  {item[item2.key]}%
                </div>
              ) : item2.key === "status" ? (
                <div
                  style={{
                    backgroundColor:
                      item[item2.key] === "تم الانتهاء منه" ? "#00C973" : "red",
                    color: "white",
                    borderRadius: "14px",
                    padding: "5px 0px",
                  }}
                >
                  {item[item2.key]}
                </div>
              ) : (
                item[item2.key]
              )}
            </td>
          ))}
        </tr>
      )
    );
  }

  if (instructor.WorkRecord) {
    WorkRecordShow = instructor.WorkRecord.map((item, index) => (
      <tr key={index} style={{ width: "100%", textAlign: "center" }}>
        {headerOD.map((item2, key2) => (
          <td
            className={`table-header ${
              item2.key === "status" || item2.key === "program_code"
                ? "name-column"
                : "small-column"
            }`}
            style={{ overflow: "auto", maxWidth: "200px" }}
            key={key2}
          >
            {item2.key === "attendence" ? (
              <button
                className="btn btn-sm"
                onClick={() => {
                  setSelectedAttendance(item.attendence);
                  setShowAttendanceModal(true);
                }}
                style={{
                  backgroundColor: "#FEEFDE",
                  color: "#F89523",
                  border: "none",
                  borderRadius: "8px",
                  padding: "4px 12px",
                  fontWeight: "500",
                }}
              >
                الحضور
              </button>
            ) : (
              item[item2.key]
            )}
          </td>
        ))}
      </tr>
    ));
  }

  return (
    <>
      {loading && <Loading />}
      <div className="trainee">
        <DetailsTopBarUsers
          title="المحاضرين"
          button="تعديل"
          icon="edit"
          backIcon={true}
          link={`/dashboard/usersMangement/instructors/edit/${id}`}
          linkBack={`/dashboard/usersMangement/instructors`}
          linkParent="ادارة المستخدمين"
          linkChild="المحاضرين"
          name={instructor.name}
          sendSuccess={true}
        />
        <div className="show-trainee-avatar">
          <img
            // instructor image from API
            src={require("./../../images/Bright Colorful Young Man Avatar.jpg")}
            alt=""
          ></img>
          <div className="name-trainee">
            <p style={{ color: "black", fontWeight: "500" }}>
              {instructor.name}
            </p>
            <p>{instructor.email}</p>
          </div>
        </div>
        <div className="personal-info">
          <h5
            className="personal-info-h5"
            style={{ color: "black", fontWeight: "500" }}
          >
            المعلومات الشخصية
          </h5>
          <div className="info-parent d-flex align-items-center justify-content-between flex-wrap col-lg-12 col-md-2 col-2 ">
            <div className="p-4 info-1" style={{ width: "40%" }}>
              <div className="d-flex align-items-center justify-content-between p-2">
                <p>رقم الهاتف</p>
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    padding: "6px 20px",
                  }}
                >
                  {instructor.phone_number}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between p-2">
                <p>المحافظة</p>
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    padding: "6px 20px",
                  }}
                >
                  {instructor.governorate}
                </p>
              </div>
            </div>
            <div className="info-1 p-4" style={{ width: "40%" }}>
              <div className="d-flex align-items-center justify-content-between p-2">
                <p>الدولة</p>
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    padding: "6px 20px",
                  }}
                >
                  {instructor.country}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between p-2">
                <p>المدينة</p>
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    padding: "6px 20px",
                  }}
                >
                  {instructor.city}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="personal-info">
          <h5
            className="personal-info-h5"
            style={{ color: "black", fontWeight: "500" }}
          >
            المعلومات المهنية
          </h5>
          <div className="info-parent d-flex align-items-center justify-content-between flex-wrap col-lg-12 col-md-2 col-2 ">
            <div className="p-4 info-1" style={{ width: "40%" }}>
              <div className="d-flex align-items-center justify-content-between p-2 w-100">
                <p> تاريخ الانضمام للمنصة </p>
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    padding: "6px 20px",
                  }}
                >
                  {instructor.date_of_joining}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between p-2">
                <p>ساعات التدريب</p>
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    padding: "6px 20px",
                  }}
                >
                  {instructor.training_hours}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between p-2">
                <p>معدل الاجر في الساعة</p>
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    padding: "6px 20px",
                  }}
                >
                  {instructor.average_per_hour}
                </p>
              </div>
            </div>
            <div className="info-1 p-4" style={{ width: "40%" }}>
              <div className="d-flex align-items-center justify-content-between p-2">
                <p> المسار التدريبي</p>
                <p
                  style={{
                    fontWeight: "500",
                    padding: "6px 20px",
                    borderRadius: "80px",
                    backgroundColor:
                      instructor.training_path === "التطوير المؤسسي"
                        ? "#FADEDE"
                        : instructor.training_path === "ادارة المواهب"
                        ? "#FCF4DA"
                        : "#B0E8F2",
                    color:
                      instructor.training_path === "التطوير المؤسسي"
                        ? "#DC2626"
                        : instructor.training_path === "ادارة المواهب"
                        ? "#EAB308"
                        : "#00B5D4",
                  }}
                >
                  {instructor.training_path}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between p-2">
                <p>الوظيفة</p>
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    padding: "6px 20px",
                  }}
                >
                  {instructor.job}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between p-2">
                <p>التقييم</p>
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    padding: "6px 20px",
                  }}
                >
                  {instructor.rate}
                </p>
              </div>
            </div>
            <div className="p-4 info-1" style={{ width: "60%" }}>
              <div className="d-flex align-items-center justify-content-between p-2 ">
                <p>رابط لينكدان</p>
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    padding: "6px 20px",
                  }}
                >
                  {instructor.linkedin}
                </p>
              </div>
            </div>
            <div className="p-4 info-1" style={{ width: "100%" }}>
              <div className="d-flex align-items-baseline justify-content-between p-2 ">
                <p>السيرة الذاتية </p>
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    padding: "6px 20px",
                    overflow: "auto",
                  }}
                >
                  {instructor.cv}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="personal-info">
          <h5
            className="personal-info-h5"
            style={{
              color: "black",
              fontWeight: "500",
              marginBottom: "20px",
            }}
          >
            المهارات
          </h5>
          <div className="">
            <div className="p-4 info-1 d-flex align-items-center gap-4 flex-wrap col-lg-12 col-md-4 col-2">
              {skillsShow}
            </div>
          </div>
        </div>
        <div className="personal-info">
          <h5
            className="personal-info-h5"
            style={{ color: "black", fontWeight: "500" }}
          >
            الشهادات الحاصل عليها
          </h5>
          <div className="">
            <div className="formtable p-4 info-1 d-flex align-items-center gap-4 flex-wrap col-lg-12 col-md-4 col-2">
              <Table
                striped
                bordered
                hover
                className="custom-table-ShowTrainee"
              >
                <thead className="custom-thead">
                  <tr>
                    <div
                      style={{
                        border: "1px solid #e0e0e0",
                        borderRadius: "50px",
                        overflow: "hidden",
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {headersShow}
                    </div>
                  </tr>
                </thead>
                <tbody>
                  <div
                    style={{
                      border: "1px solid #e0e0e0",
                      borderRadius: "30px",
                      overflow: "hidden",
                      padding: "16px",
                      marginTop: "10px",
                    }}
                  >
                    {certificatesObtainedShow}
                  </div>
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        <div className="personal-info">
          <h5
            className="personal-info-h5"
            style={{ color: "black", fontWeight: "500" }}
          >
            سجل العمل في OD
          </h5>
          <div className="">
            <div className="formtable p-4 info-1 d-flex align-items-center gap-4 flex-wrap col-lg-12 col-md-4 col-2">
              <Table
                striped
                bordered
                hover
                className="custom-table-ShowTrainee"
              >
                <thead className="custom-thead">
                  <tr>
                    <div
                      style={{
                        border: "1px solid #e0e0e0",
                        borderRadius: "50px",
                        overflow: "hidden",
                      }}
                    >
                      {headersODShow}
                    </div>
                  </tr>
                </thead>
                <tbody>
                  <div
                    style={{
                      border: "1px solid #e0e0e0",
                      borderRadius: "30px",
                      overflow: "hidden",
                      padding: "16px",
                      marginTop: "10px",
                    }}
                  >
                    {WorkRecordShow}
                  </div>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        {/* Modal */}
        <Modal
          show={showAttendanceModal}
          onHide={() => setShowAttendanceModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ textAlign: "center" }}>الحضور</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table className="custom-table-ShowTrainee" striped bordered hover>
              <thead>
                <tr>
                  <div
                    style={{
                      border: "1px solid #e0e0e0",
                      borderRadius: "50px",
                      overflow: "hidden",
                    }}
                  >
                    {attendanceHeader.map((header) => (
                      <th
                        key={header.key}
                        style={{
                          textAlign: "center",
                          maxWidth: "125px",
                          overflow: "auto",
                        }}
                      >
                        {header.name}
                      </th>
                    ))}
                  </div>
                </tr>
              </thead>
              <tbody>
                <div
                  style={{
                    border: "1px solid #e0e0e0",
                    borderRadius: "30px",
                    overflow: "hidden",
                    padding: "16px",
                    marginTop: "10px",
                  }}
                >
                  {selectedAttendance?.map((attendee, index) => (
                    <tr key={index}>
                      {attendanceHeader.map((header) => (
                        <td
                          key={header.key}
                          style={{
                            textAlign: "center",
                            maxWidth: "125px",
                            overflow: "auto",
                          }}
                        >
                          <p
                            style={{
                              maxWidth: "80px",
                              borderRadius: "30px",
                              wordBreak: "break-word",

                              backgroundColor:
                                header.key === "experience" ? "#D9F4F9" : "",
                              color:
                                header.key === "experience" ? "#00B5D4" : "",
                            }}
                          >
                            {attendee[header.key]}
                          </p>
                        </td>
                      ))}
                    </tr>
                  ))}
                </div>
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
