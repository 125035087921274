import React, { useState } from "react";

const WorkshopsSection = ({
  workshops,
  onWorkshopsChange,
  head_title,
  empty,
  toolsSelected,
}) => {
  const [inputValueWorkShop, setInputValueWorkShop] = useState("");

  const handleKeyDownWorkShop = (e) => {
    if (e.key === "Enter" && inputValueWorkShop.trim()) {
      e.preventDefault();
      const newWorkshops = [...workshops, inputValueWorkShop.trim()];
      onWorkshopsChange(newWorkshops);
      setInputValueWorkShop("");
    }
  };

  const handleAddWorkShop = (e) => {
    e.preventDefault();
    if (inputValueWorkShop.trim()) {
      const newWorkshops = [...workshops, inputValueWorkShop.trim()];
      onWorkshopsChange(newWorkshops);
      setInputValueWorkShop("");
    }
  };

  const handleDeleteWorkShop = (indexToDelete) => {
    const newWorkshops = workshops.filter(
      (_, index) => index !== indexToDelete
    );
    onWorkshopsChange(newWorkshops);
  };
  const workshopsDefault = [
    "تخطيط القوة العاملة (10-ساعات)",
    "تخطيط القوة العاملة (10-ساعات)",
    "نظام ادارة الاداء(18-ساعه)",
    "نظام ادارة الاداء(18-ساعه)",
  ];
  const toolsDefault = [
    "تخطيط القوة العاملة (10-ساعات)",
    "تخطيط القوة العاملة (10-ساعات)",
    "نظام ادارة الاداء(18-ساعه)",
    "نظام ادارة الاداء(18-ساعه)",
  ];
  return (
    <div className="personal-info">
      <h5
        className="personal-info-h5"
        style={{
          color: "black",
          fontWeight: "500",
          marginBottom: "20px",
        }}
      >
        {head_title}
      </h5>
      <div className="">
        <div className="p-1 info-1 d-flex align-items-center gap-4 flex-wrap col-lg-12 col-md-4 col-2">
          <div
            className="w-full bg-white rounded-2xl shadow-[0_2px_8px_rgba(0,0,0,0.08)] p-1"
            dir="rtl"
          >
            {/* Header */}
            <div className="flex justify-between items-center mb-4 flex-wrap">
              <div className="relative-input mb-1 ">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onKeyDown={handleKeyDownWorkShop}
                  onChange={(e) => setInputValueWorkShop(e.target.value)}
                  value={inputValueWorkShop}
                  placeholder="اضف دورة اخرى"
                  
                >
                  <option style={{ display: "none" }} selected></option>
                  {!empty
                    ? workshops.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))
                    :toolsSelected ? toolsDefault.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      )) :  workshopsDefault.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                </select>
              </div>
              <button
                onClick={handleAddWorkShop}
                className="text-[#ff6b00] hover:text-[#ff8533] text-sm flex items-center gap-1 transition-colors duration-200 font-medium skills-btn"
              >
                <span className="text-lg" style={{ fontSize: "25px" }}>
                  +
                </span>
                <span>إضافة</span>
              </button>
            </div>

            {/* Workshops List */}
            <div className="info-parent d-flex flex-wrap col-lg-12 col-md-2 col-2">
              <div
                className="maker d-flex flex-column gap-2"
                style={{ width: "50%" }}
              >
                {workshops.map((item, index) => (
                  <div
                    className="d-flex align-items-center p-2 gap-2"
                    key={index}
                  >
                    <img
                      src={require("./../../../../Pages/Dashboard/images/close-circle.png")}
                      alt="tick-circle"
                      onClick={() => handleDeleteWorkShop(index)}
                      style={{ cursor: "pointer" }}
                    />
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        color: "black",
                      }}
                    >
                      {item}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkshopsSection;
