import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import WindowContext from "./Context/WindowContext";
import MenuContext from "./Context/MenuContext";
import LinksContext from "./Context/LinksContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import {links} from "./Components/Dashboard/SideBar/NavLinks/NavLink"

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  
    <WindowContext>
      <MenuContext>
        <BrowserRouter>
        <LinksContext links={links}>
          <App />
          </LinksContext>
        </BrowserRouter>
      </MenuContext>
    </WindowContext>
  
);
