import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Menu } from "../../../Context/MenuContext";
import { WindowWidth } from "../../../Context/WindowContext";
import { links } from "./NavLinks/NavLink";
import "./SideBar.css";
import { Links } from "../../../Context/LinksContext";

export default function SideBar() {
   const MyMenu = useContext(Menu);
   const Open_Menu = MyMenu.isOpen;
   const windowWidth = useContext(WindowWidth);
   const { updateLinks, linksEntered } = useContext(Links);
   const location = useLocation();

   const [activeSubmenu, setActiveSubmenu] = useState(null);

   // تحديد القائمة النشطة عند تحميل الصفحة
   useEffect(() => {
     for (let i = 0; i < links.length; i++) {
       const link = links[i];
       if (link.hasSubmenu) {
         const submenuItem = link.submenu.find(
           (sub) => sub.path === location.pathname
         );
         if (submenuItem) {
           setActiveSubmenu(i);
           break;
         }
       }
     }
   }, [location.pathname]);

   const toggleSubmenu = (index, parentName) => {
     if (activeSubmenu === index) {
       setActiveSubmenu(null);
       if (linksEntered.child) {
         updateLinks(parentName, null);
       }
     } else {
       setActiveSubmenu(index);
       updateLinks(parentName, null);
     }
   };

   const handleDirectLink = (linkName) => {
     updateLinks(linkName);
   };

   const handleSubmenuLink = (parentName, childName) => {
     updateLinks(parentName, childName);
   };

   // دالة مساعدة لتحديد إذا كان الرابط نشط
   const isLinkActive = (link) => {
     if (link.hasSubmenu) {
       return link.submenu.some((sub) => sub.path === location.pathname);
     }
     return link.path === location.pathname;
   };

  return (
    <div className="sidebar-wrapper">
      {windowWidth.width < "768" && (
        <div
          className="overlay"
          onClick={() => MyMenu.setIsOpen(false)}
          style={{
            display:
              windowWidth.width > "768" ? "none" : Open_Menu ? "block" : "none",
          }}
        />
      )}
      <div
        className={`side-bar ${Open_Menu ? "open" : ""}`}
        style={{
          marginRight:
            windowWidth.width < "768" ? (Open_Menu ? "20px" : "-100%") : "20px",
          marginTop:
            windowWidth.width < "768"
              ? Open_Menu
                ? "100px"
                : "100px"
              : "20px",
        }}
      >
        <div className="side-bar-photo d-flex justify-content-center">
          <img
            style={{
              display:
                windowWidth.width < "768"
                  ? Open_Menu
                    ? "block"
                    : "none"
                  : "block",
            }}
            src={require("./NavLinks/images/Frame 1.png")}
            alt=""
          />
        </div>
        <div>
          {links.map((link, index) => (
            <div key={index} className="nav-item">
              {link.hasSubmenu ? (
                <div
                  className={`d-flex align-items-center gap-2 side-bar-link ${
                    isLinkActive(link) || activeSubmenu === index
                      ? "active"
                      : ""
                  }`}
                  onClick={() => toggleSubmenu(index, link.name)}
                >
                  <img src={link.icon} alt="s" />
                  <p className="m-0">{link.name}</p>
                  <FontAwesomeIcon
                    icon={activeSubmenu === index ? faChevronUp : faChevronDown}
                    className="ms-auto submenu-arrow"
                  />
                </div>
              ) : (
                <NavLink
                  to={link.path}
                  end
                  className={({ isActive }) =>
                    isActive
                      ? "d-flex align-items-center gap-2 side-bar-link active"
                      : "d-flex align-items-center gap-2 side-bar-link"
                  }
                  onClick={() => handleDirectLink(link.name)}
                >
                  <img src={link.icon} alt="s" />
                  <p className="m-0">{link.name}</p>
                </NavLink>
              )}

              {link.hasSubmenu && activeSubmenu === index && (
                <div className="submenu">
                  {link.submenu.map((subItem, subIndex) => (
                    <NavLink
                      key={subIndex}
                      to={subItem.path}
                      onClick={() => handleSubmenuLink(link.name, subItem.name)}
                      className={({ isActive }) =>
                        isActive
                          ? "d-flex align-items-center gap-2 side-bar-link submenu-item active"
                          : "d-flex align-items-center gap-2 side-bar-link submenu-item"
                      }
                    >
                      <img src={subItem.icon} alt="s" />
                      <p className="m-0">{subItem.name}</p>
                    </NavLink>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
