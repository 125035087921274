import { Form, FormSelect, Table } from "react-bootstrap";
import "./Instructors.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import PaginatedItems from "../../../../Components/Dashboard/Pagination/Pagination";
import DropdownMenu from "../../../../Components/Dashboard/Helpers/DropdownMenu ";
import Loading from "../../../../Components/Dashboard/Loading/Loading";
import DetailsTopBarUsers from "../../../../Components/Dashboard/DetailsTop/DetailsTopBarUsers";

export default function Instructors() {
  const [instructors, setinstructorss] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(6);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const dataPaginated = instructors.slice((page - 1) * limit, page * limit);
  function isNumber(value) {
    return /^\d+$/.test(value); // check if the value is a number only
  }

  function handleSearch(e) {
    const value = e.target.value;
    setSearch(value);
  }
  console.log(instructors);
  // inside the const filterdData
  const filterdData = instructors.filter((item) => {
    const searchField = isNumber(search) ? "phone_number" : "name";
    return (
      item[searchField].toLowerCase().includes(search.toLowerCase()) ||
      item["email"].toLowerCase().includes(search.toLowerCase())
    );
  });

  const showWhichData = search.length > 0 ? filterdData : dataPaginated;
  const header = [
    {
      key: "id",
      name: "الكود",
    },
    {
      key: "name",
      name: "الاسم",
    },
    {
      key: "training_path",
      name: "المسار التدريبي",
    },
    {
      key: "email",
      name: "البريد الالكتروني",
    },
    {
      key: "date_of_joining",
      name: "تاريخ الانضمام",
    },

    {
      key: "select",
      name: "",
    },
  ];
  //Get  User
  useEffect(() => {
    try {
      setLoading(true);
      axios.get("/JSON/Instructors.json").then((res) => {
        setinstructorss(res.data);
        console.log(res.data);
        setTotal(res.data.length);
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const headersShow = header.map((item, index) => (
    <th
      className={`table-header ${
        item.key === "name"
          ? "name-column"
          : item.key === "id"
          ? "small-column"
          : ""
      }`}
    >
      {item.name}
    </th>
  ));
  const [openedDropdown, setOpenedDropdown] = useState(null);

  const instructorsShow = showWhichData.map((item, index) => (
    <tr className="table-row">
      {header.map((item2, key2) => (
        <td
          className={
            item2.key === "name"
              ? "name-column"
              : item2.key === "id"
              ? "small-column"
              : ""
          }
          key={key2}
        >
          {item2.key === "training_path" ? (
            <div
              className="show-status"
              style={{
                backgroundColor:
                  item[item2.key] === "التطوير المؤسسي"
                    ? "#FADEDE"
                    : item[item2.key] === "ادارة المواهب"
                    ? "#FCF4DA"
                    : "#B0E8F2",
                color:
                  item[item2.key] === "التطوير المؤسسي"
                    ? "#DC2626"
                    : item[item2.key] === "ادارة المواهب"
                    ? "#EAB308"
                    : "#00B5D4",
              }}
            >
              {item[item2.key]}
            </div>
          ) : item2.key === "select" ? (
            <div className="dropdown-container">
              <FontAwesomeIcon
                onClick={() =>
                  setOpenedDropdown(openedDropdown === index ? null : index)
                }
                cursor="pointer"
                icon={faEllipsis}
              />
              {openedDropdown === index && (
                <DropdownMenu
                  className="faEllipsis-drop"
                  id={item["id"]}
                  onClose={() => setOpenedDropdown(null)}
                />
              )}
            </div>
          ) : (
            item[item2.key]
          )}
        </td>
      ))}
    </tr>
  ));
  return (
    <>
      {loading && <Loading />}
      <div className="trainee">
        <DetailsTopBarUsers
          title="بيانات المحاضرين"
          button="اضف متدرب جديد"
          icon="plus"
          link="/dashboard/usersMangement/instructors/add"
          linkParent="ادارة المستخدمين"
          linkChild="المحاضرين"
          sendSuccess={true}
        />
        <div className="trainee-table">
          <div className="search-parent">
            <Form.Control
              className="search-trainee my-2"
              type="search"
              aria-label="input example"
              placeholder="ابحث بالاسم ، رقم الهاتف او البريد الالكتروني"
              onChange={(e) => {
                handleSearch(e);
                // setSearchLoading(true);
              }}
            />
            <i className="search-icon-trainee"></i>
          </div>
          <div className="formtable">
            <Table striped bordered hover className="custom-table">
              <thead>
                <tr>{headersShow}</tr>
              </thead>
              <tbody>{instructorsShow}</tbody>
            </Table>
          </div>
          <div className="pagination-parent" style={{ direction: "rtl" }}>
            <div className="d-flex align-items-center justify-content-between">
              <p style={{ margin: "10px" }}>يوجد</p>
              <FormSelect
                class="form-select"
                id="floatingSelect"
                aria-label="Floating label select example"
                style={{ width: "110px", color: "#f89523" }}
                onChange={(e) => setLimit(e.target.value)}
              >
                <option value="6">6</option>
                <option value="8">8</option>

                <option value="10">10</option>
                <option value="15">15</option>
              </FormSelect>
              <p style={{ margin: "10px" }}>مستخدمين فالصفحة الواحدة</p>
            </div>
            <PaginatedItems
              setPage={setPage}
              itemsPerPage={limit}
              data={instructors}
              total={total}
            />
          </div>
        </div>
      </div>
    </>
  );
}
