import DetailsTopBar from "../DetailsTop/DetailsTopBarUsers";
import HeaderTopBar from "./HeaderTopBar";
import { WindowWidth } from "./../../../Context/WindowContext";
import "./TopBar.css"; // ملف CSS الخارجي
import { useContext } from "react";

function TopBar() {
  const windowWidth = useContext(WindowWidth);
  return (
    <div
      className="topbar"
      style={{
        width:
          windowWidth.width < "768"
            ? "100%"
            : windowWidth.width < "1562"
            ? "60%"
            : "81%",
        position: windowWidth.width < "768" ? "fixed" : "sticky",
      }}
    >
      <HeaderTopBar />
      {/* <DetailsTopBar/> */}
    </div>
  );
}

export default TopBar;
