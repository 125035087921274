import { Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./Pages/Website/HomePage";
import Dashboard from "./Pages/Dashboard/Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import Trainees from "./Pages/Dashboard/UsersMangement/Trainees/Trainees";
import LinksContext from "./Context/LinksContext";
import { links } from "./Components/Dashboard/SideBar/NavLinks/NavLink";
import ShowTrainee from "./Pages/Dashboard/UsersMangement/Trainees/ShowTrainee";
import UpdateTrainee from "./Pages/Dashboard/UsersMangement/Trainees/UpdateTrainee";
import AddTrainee from "./Pages/Dashboard/UsersMangement/Trainees/ِAddTrainee";
import Instructors from "./Pages/Dashboard/UsersMangement/Instructors/Instructors";
import ShowInstructor from "./Pages/Dashboard/UsersMangement/Instructors/ShowInstructor";
import AddInstructor from "./Pages/Dashboard/UsersMangement/Instructors/AddInstructor";
import UpdateInstructor from "./Pages/Dashboard/UsersMangement/Instructors/UpdateInstructor";
import Certificates from "./Pages/Dashboard/OperationsManagement/Certificates/Certificates";
import Attendence from "./Pages/Dashboard/OperationsManagement/Certificates/Attendence/Attendance";
import CertificatesContent from "./Pages/Dashboard/OperationsManagement/Certificates/CertificatesContent/CertificatesContent";
import ShowCetificate from "./Pages/Dashboard/OperationsManagement/Certificates/CertificatesContent/ShowCertificate";
import UpdateCertificate from "./Pages/Dashboard/OperationsManagement/Certificates/CertificatesContent/UpdateCertificate";
import AddCertificate from "./Pages/Dashboard/OperationsManagement/Certificates/CertificatesContent/AddCertificate";

function App() {
  return (
    <div className="App">
      <LinksContext links={links}>
        <Routes>
          <Route path="/" element={<HomePage />}></Route>
          {/* Dashboard */}
          <Route path="/dashboard" element={<Dashboard />}>
            {/* Users Mangement Trainees */}
            <Route
              path="usersMangement/trainees"
              element={<Trainees />}
            ></Route>
            <Route
              path="usersMangement/trainees/:id"
              element={<ShowTrainee />}
            ></Route>
            <Route
              path="usersMangement/trainees/edit/:id"
              element={<UpdateTrainee />}
            ></Route>
            <Route
              path="usersMangement/trainees/add"
              element={<AddTrainee />}
            ></Route>
            {/* Users Mangement Instructors */}
            <Route
              path="usersMangement/instructors"
              element={<Instructors />}
            ></Route>
            <Route
              path="usersMangement/instructors/:id"
              element={<ShowInstructor />}
            ></Route>
            <Route
              path="usersMangement/instructors/edit/:id"
              element={<UpdateInstructor />}
            ></Route>
            <Route
              path="usersMangement/instructors/add"
              element={<AddInstructor />}
            ></Route>
            {/* Operations Mangement Certificates */}
            <Route
              path="operationsMangement/certificates"
              element={<Certificates />}
            >
              <Route index element={<CertificatesContent />} />
              <Route path=":id" element={<ShowCetificate />}></Route>
              <Route path="add" element={<AddCertificate />}></Route>
              <Route path="edit/:id" element={<UpdateCertificate />}></Route>
              <Route path="attendence" element={<Attendence />}></Route>
            </Route>
          </Route>
        </Routes>
      </LinksContext>
    </div>
  );
}

export default App;
